<div class="flex drawer-content">
  <div class="flex-1 border-r-2 pr-[12px]" *ngIf="!isHide">
    <div class="flex items-center justify-between mb-[16px]">
      <h1 class="text-[13px] text-slate-700 leading-3 font-medium">
        {{ leftTitle }}
      </h1>
      <span
        class="text-primary text-[10px] cursor-pointer leading-3"
        (click)="collapseAllGroups()"
        >Collapse all groups</span
      >
    </div>
    <mat-form-field class="w-full height-40">
      <mat-icon matPrefix>search</mat-icon>
      <input
        name="searchgroup"
        matInput
        class="p-0"
        placeholder="Search by name"
        #searchAttr
        [(ngModel)]="searchText"
        (ngModelChange)="searchAttribute(searchText)" />
      <mat-icon
        matPostfix
        *ngIf="
          searchAttr.value != '' &&
          searchAttr.value != null &&
          searchAttr.value != undefined
        "
        class="cursor-pointer"
        (click)="searchAttr.value = ''; clearSearchAttribute()"
        >close</mat-icon
      >
    </mat-form-field>
    <div class="scrollable-attribute-container">
      <mat-accordion
        [multi]="true"
        class="divide-y"
        #attributeTree="matAccordion">
        <mat-expansion-panel
          *ngFor="let group of attributeGroups; let gi = index"
          [togglePosition]="'before'"
          #panel1
          [hideToggle]="true"
          class="my-[0px] accordion-tree-view">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-checkbox
                (click)="$event.stopPropagation()"
                class="authorize-click mr-[0px] flex items-center"
                [checked]="group.allSelected"
                color="primary"
                [indeterminate]="
                  group.name == 'Integration attributes'
                    ? isIntermediateIntegrationSelected(gi)
                    : isIntermediateSelected(gi)
                "
                (change)="
                  group.name == 'Integration attributes'
                    ? selectAllIntegrationAttribute($event.checked, -1)
                    : selectAllAttribute($event.checked, gi)
                ">
              </mat-checkbox>
              <button
                class="small-button authorize-click"
                mat-icon-button
                (click)="$event.stopPropagation(); panel1.toggle()">
                <mat-icon
                  *ngIf="!panel1.expanded"
                  svgIcon="mat_outline:keyboard_arrow_right"></mat-icon>
                <mat-icon
                  *ngIf="panel1.expanded"
                  svgIcon="mat_outline:keyboard_arrow_down"></mat-icon>
              </button>
              <span>{{ group.name }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div
            *ngIf="group.name !== 'Integration attributes'"
            class="flex flex-col">
            <mat-checkbox
              class="h-[33px] flex items-center ml-[12px] mb-[5px] px-[15px] rounded-md bg-slate-100 full-width-checkbox text-[13px]"
              color="primary"
              *ngFor="let attribute of group.attributes"
              (change)="onSelectAttribute(attribute, $event.checked)"
              [checked]="
                lodash.some(selectedAttributesLocalHandling, [
                  'code',
                  attribute.code
                ])
              "
              [disabled]="non_closable_codes.includes(attribute.code)"
              >{{ attribute.label }}</mat-checkbox
            >
          </div>

          <mat-accordion
            *ngIf="group.name == 'Integration attributes'"
            multi
            class="divide-y"
            #attribiteIntegerTree="matAccordion">
            <mat-expansion-panel
              *ngFor="let intGroup of group.integrations; let igi = index"
              [togglePosition]="'before'"
              #panel_igi
              [hideToggle]="true"
              class="my-[0px] accordion-tree-view">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    class="authorize-click mr-[0px] flex items-center"
                    [checked]="intGroup.allSelected"
                    color="primary"
                    [indeterminate]="
                      isIntermediateIntegrationSelected(igi, intGroup)
                    "
                    (change)="
                      selectAllIntegrationAttribute($event.checked, igi)
                    ">
                  </mat-checkbox>
                  <button
                    class="small-button authorize-click"
                    mat-icon-button
                    (click)="$event.stopPropagation(); panel_igi.toggle()">
                    <mat-icon
                      *ngIf="!panel_igi.expanded"
                      svgIcon="mat_outline:keyboard_arrow_right"></mat-icon>
                    <mat-icon
                      *ngIf="panel_igi.expanded"
                      svgIcon="mat_outline:keyboard_arrow_down"></mat-icon>
                  </button>
                  <span>{{ intGroup.name }}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="flex flex-col">
                <mat-checkbox
                  class="h-[33px] flex items-center ml-[12px] mb-[5px] px-[15px] rounded-md bg-slate-100 full-width-checkbox text-[13px]"
                  color="primary"
                  *ngFor="
                    let intAttribute of intGroup.attributes;
                    let i = index
                  "
                  (change)="
                    onSelectIntegrationAttribute(
                      intAttribute,
                      $event.checked,
                      intGroup,
                      i
                    )
                  "
                  [checked]="
                    lodash.some(getListing(intGroup), [
                      'code',
                      intAttribute.code
                    ])
                  "
                  [disabled]="non_closable_codes.includes(intAttribute.code)"
                  >{{ intAttribute.label }}</mat-checkbox
                >
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <div class="flex-1 pl-[12px]">
    <div class="flex items-center justify-between mb-[16px]">
      <div class="flex items-center">
        <h1 class="text-[13px] text-slate-700 leading-3 font-medium">
          {{ rightTitle }}
        </h1>
      </div>
      <span
        *ngIf="!isHide"
        class="text-primary text-[10px] cursor-pointer leading-3"
        (click)="clearAllSelectedLabels()"
        >Clear All</span
      >
    </div>
    <mat-form-field class="w-full height-40">
      <mat-icon matPrefix>search</mat-icon>
      <input
        name="searchlabel"
        matInput
        class="p-0"
        placeholder="Search by name"
        #searchSelectedAttr
        [(ngModel)]="selectedAttrQuery"
        (keyup)="searchSelectedAttribute(searchSelectedAttr.value)" />
      <mat-icon
        matPostfix
        *ngIf="
          selectedAttrQuery != '' &&
          selectedAttrQuery != null &&
          selectedAttrQuery != undefined
        "
        class="cursor-pointer"
        (click)="selectedAttrQuery = ''; clearSearchSelectedAttribute()"
        >close</mat-icon
      >
    </mat-form-field>
    <ng-container
      *ngFor="
        let attribute of selectedAttributesLocalHandling
          | slice: 0:non_dragable_codes.length
          | search: 'label':selectedAttrQuery:'I'
      ">
      <div
        class="mb-[5px] pl-[5px] pr-[15px] py-[4px] rounded-md bg-slate-100 flex items-center justify-between text-[13px] cursor-grab column-toggler-item h-[33px]">
        <div class="flex items-center">
          <span
            class="uppercase color-success mr-[8px] ml-[34px] font-medium"
            *ngIf="attribute?.attribute_type == attribute_Types.system"
            >sys</span
          >
          <span
            class="uppercase color-success mr-[8px] font-medium"
            *ngIf="attribute?.attribute_type == attribute_Types.integration"
            >int</span
          >
          <span
            class="uppercase color-success mr-[8px] font-medium"
            *ngIf="
              attribute?.attribute_type != attribute_Types.integration &&
              attribute?.attribute_type != attribute_Types.system
            "
            >att</span
          >
          <span>{{ attribute.label }}</span>
        </div>
      </div>
    </ng-container>
    <div
      class="scrollable-attribute-container"
      cdkDropList
      (cdkDropListDropped)="handleDropColumn($event)"
      cdkDropListGroup
      [cdkDropListData]="selectedAttributesLocalHandling">
      <ng-container
        *ngFor="
          let attribute of selectedAttributesLocalHandling
            | slice: non_dragable_codes.length;
          let pi = index
        ">
        <div
          *ngIf="!attribute.integration_id"
          class="mb-[5px] pl-[5px] pr-[15px] py-[4px] rounded-md bg-slate-100 flex items-center justify-between text-[13px] cursor-grab column-toggler-item"
          cdkDrag
          cdkDragLockAxis="y"
          [cdkDragDisabled]="non_dragable_codes.includes(attribute.code)">
          <div class="flex items-center">
            <mat-icon
              svgIcon="mat_outline:drag_indicator"
              class="mr-[10px]"></mat-icon>
            <span
              class="uppercase color-success mr-[8px] font-medium"
              *ngIf="attribute?.attribute_type == attribute_Types.system"
              >sys</span
            >
            <span
              class="uppercase color-success mr-[8px] font-medium"
              *ngIf="attribute?.attribute_type == attribute_Types.integration"
              >int</span
            >
            <span
              class="uppercase color-success mr-[8px] font-medium"
              *ngIf="
                attribute?.attribute_type != attribute_Types.integration &&
                attribute?.attribute_type != attribute_Types.system
              "
              >att</span
            >
            <span>{{ attribute.label }}</span>
          </div>
          <button
            mat-icon-button
            *ngIf="!isHide && !non_closable_codes.includes(attribute.code)"
            class="small-button mr-0"
            (click)="unselectAttribute(attribute)">
            <mat-icon
              matPostfix
              class="cursor-pointer p-[4px]"
              svgIcon="mat_outline:close"></mat-icon>
          </button>
        </div>

        <div
          *ngIf="attribute.integration_id"
          cdkDrag
          cdkDragLockAxis="y"
          [cdkDragDisabled]="non_dragable_codes.includes(attribute.code)"
          class="mb-[5px] pl-[5px] pr-[15px] py-[4px] rounded-md bg-gray-200 flex items-center justify-between cursor-grab column-toggler-item">
          <div class="w-full">
            <div class="flex items-center justify-between pb-2">
              <div class="flex items-center">
                <mat-icon
                  svgIcon="mat_outline:drag_indicator"
                  class="mr-[10px]"></mat-icon>
                <span>{{ attribute.integration_name }}</span>
              </div>
              <button
                mat-icon-button
                *ngIf="!isHide && !non_closable_codes.includes(attribute.code)"
                class="small-button mr-0"
                (click)="unselectIntegrationAttribute(pi, -1)">
                <mat-icon
                  matPostfix
                  class="cursor-pointer p-[4px]"
                  svgIcon="mat_outline:close"></mat-icon>
              </button>
            </div>
            <div
              cdkDropList
              [cdkDropListData]="attribute.attributes"
              (cdkDropListDropped)="handleDropIntegrationColumn($event, pi)">
              <ng-container
                *ngFor="
                  let intAtribute of attribute.attributes
                    | search: 'label':selectedAttrQuery:'I';
                  let ci = index
                ">
                <div
                  class="mb-[5px] py-[4px] rounded-md bg-slate-100 flex items-center justify-between text-[13px] cursor-grab column-toggler-item"
                  cdkDrag
                  cdkDragLockAxis="y"
                  [cdkDragDisabled]="
                    non_dragable_codes.includes(intAtribute.code)
                  ">
                  <div class="flex items-center">
                    <mat-icon
                      svgIcon="mat_outline:drag_indicator"
                      class="mr-[10px]"></mat-icon>
                    <span
                      class="uppercase color-success mr-[8px] font-medium"
                      *ngIf="
                        intAtribute?.attribute_type == attribute_Types.system
                      "
                      >sys</span
                    >
                    <span
                      class="uppercase color-success mr-[8px] font-medium"
                      *ngIf="
                        intAtribute?.attribute_type ==
                        attribute_Types.integration
                      "
                      >int</span
                    >
                    <span
                      class="uppercase color-success mr-[8px] font-medium"
                      *ngIf="
                        intAtribute?.attribute_type !=
                          attribute_Types.integration &&
                        intAtribute?.attribute_type != attribute_Types.system
                      "
                      >att</span
                    >
                    <span>{{ intAtribute.label }}</span>
                  </div>
                  <button
                    mat-icon-button
                    *ngIf="
                      !isHide && !non_closable_codes.includes(intAtribute.code)
                    "
                    class="small-button mr-0"
                    (click)="unselectIntegrationAttribute(pi, ci)">
                    <mat-icon
                      matPostfix
                      class="cursor-pointer p-[4px]"
                      svgIcon="mat_outline:close"></mat-icon>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { appConfig } from 'app/core/config/app.config';
import { LocalStorageUtils } from 'app/core/common/local-storage.utils';
import { SnackbarService } from 'app/services/snackbar.service';
import { EcommifyApiResponse } from 'app/core/api/api.types';
import {
  ProductGroupForm,
  ProductGroupsResponse,
} from 'app/modules/product-groups/product-groups.types';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  // Private
  private _config = appConfig;
  /**
   * Constructor
   */
  constructor(
    private http: HttpClient,
    private _snackbarService: SnackbarService
  ) {}

  getCategories(val) {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;
    return new Observable(observable => {
      this.http
        .post(`${api}/${companyID}/products/category/search`, val)
        .subscribe({
          next: (res: any) => {
            if (res.success) {
              observable.next(res.result);
            } else {
              observable.error(res);
              this._snackbarService.showError(res.message);
            }
          },
        }),
        (err: any) => {
          this._snackbarService.showError(err.error.message);
          observable.error(err);
        };
    });
  }

  getProductGroupsData(
    page: number = 0,
    size: number = 10000,
    search: string = ''
  ): Observable<EcommifyApiResponse<ProductGroupsResponse>> {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;

    let body: any = {
      page: '' + page,
      size: '' + size,
    };

    if (search) {
      body = { ...body, search };
    }

    return new Observable(observable => {
      this.http
        .post<EcommifyApiResponse<ProductGroupsResponse>>(
          `${api}/${companyID}/products/group/search`,
          body,
          { headers: { skipConversion: 'Y' } }
        )
        .subscribe({
          next: (res: any) => {
            if (res.success) {
              observable.next(res);
            } else {
              observable.error(res);
              this._snackbarService.showError(res.message);
            }
          },
        }),
        (err: any) => {
          this._snackbarService.showError(err.error.message);
          observable.error(err);
        };
    });
    // return this.http
    //   .post<EcommifyApiResponse<ProductGroupsResponse>>(
    //     `${api}/${companyID}/products/group/search`,
    //     body
    //   )
    //   .pipe(
    //     tap((response: any) => {
    //       const { result } = response;
    //       const pagination = GridUtils.getPagination(result);
    //       this._pagination.next(pagination);
    //     }),
    //     catchError((_error: any) => {
    //       return of(null);
    //     })
    //   );
  }

  createProductGroup(
    body: ProductGroupForm,
    groupId: any = null
  ): Observable<EcommifyApiResponse<ProductGroupsResponse>> {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;
    const URL = groupId
      ? `${api}/${companyID}/products/group/${groupId}`
      : `${api}/${companyID}/products/group`;
    const Method = groupId ? 'put' : 'post';
    return new Observable(observable => {
      this.http[Method]<EcommifyApiResponse<ProductGroupsResponse>>(URL, body, {
        headers: { skipConversion: 'Y' },
      }).subscribe({
        next: (res: any) => {
          if (res.success) {
            observable.next(res);
            this._snackbarService.showSuccess(res.message);
          } else {
            observable.error(res);
            this._snackbarService.showError(res.message);
          }
        },
      }),
        (err: any) => {
          this._snackbarService.showError(err.error.message);
          observable.error(err);
        };
    });
    // return this.http[Method]<EcommifyApiResponse<ProductGroupsResponse>>(
    //   URL,
    //   body
    // ).pipe(
    //   catchError((_error: any) => {
    //     return of(null);
    //   })
    // );
  }
}

/* eslint-disable */
export const syncLog = {
  syncId: 'df1e061d-b785-4168-ac18-489625071111',
  installationId: '0eb12ffd-34a8-491a-accc-df9b024f65f7',
  integrationId: '2afad8bd-7776-41ea-9a99-e7dc345c74ff',
  niceDate: '22-12-2019 10:15 PM',
  sourceName: 'Maropost',
  sourceIcon:
    'https://wordpress-631421-2579652.cloudwaysapps.com/wp-content/uploads/2022/07/44daee87-399d-45a9-b959-6ea26aedc153-2.png',
  neatSourceURL: 'pshomeandliving.co.uk',
  sourceId: 'N21511',
  destinationId: 'N729104',
  shortMessage: 'Order successfully synced',
  result: {
    badgeColor: '#4fd1c5',
    message: 'Ok',
  },
  actionRequired: false,
  showSuggestSync: false,
  showResync: true,
  isCustom: false,
  detailView: {
    payload: '',
    response: '',
    detail1: '',
  },
};

/* eslint-disable */
export const syncLogs = [
  {
    id: '689c4fcf-0571-460d-a16b-5733981bb7e9',
    active_status: 'Y',
    status: 'Inactive',
    integration: {
      id: '1ed1f116-8527-6bfa-93c1-0605e1fd6890',
      name: 'Maropost x Bunnings (Mirakl)',
      logo: 'https://wordpress-631421-2579652.cloudwaysapps.com/wp-content/uploads/2022/07/25498408_1747271388901154_6198955593483881874_n.png',
    },
    orders: {
      synced: {
        '7_days': 1,
        '14_days': 2,
        '30_days': 2,
      },
      order_value: {
        '7_days': 59.95,
        '14_days': 119.9,
        '30_days': 119.9,
      },
      percent_order_value: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
    trackings: {
      synced: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
    products: {
      synced: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
  },
  {
    id: '689c4fcf-0571-460d-a16b-5733981bb7e9',
    active_status: 'Y',
    status: 'Active',
    integration: {
      id: '1ed1f116-8527-6bfa-93c1-0605e1fd6890',
      name: 'Maropost x Bunnings (Mirakl)',
      logo: 'https://wordpress-631421-2579652.cloudwaysapps.com/wp-content/uploads/2022/07/25498408_1747271388901154_6198955593483881874_n.png',
    },
    orders: {
      synced: {
        '7_days': 1,
        '14_days': 2,
        '30_days': 2,
      },
      order_value: {
        '7_days': 59.95,
        '14_days': 119.9,
        '30_days': 119.9,
      },
      percent_order_value: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
    trackings: {
      synced: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
    products: {
      synced: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
  },
  {
    id: '689c4fcf-0571-460d-a16b-5733981bb7e9',
    active_status: 'Y',
    status: 'Error',
    integration: {
      id: '1ed1f116-8527-6bfa-93c1-0605e1fd6890',
      name: 'Maropost x Bunnings (Mirakl)',
      logo: 'https://flow-public.nimbuspop.com/flow-apps/maropost_commerce_cloud__formerly_neto_.png',
    },
    orders: {
      synced: {
        '7_days': 1,
        '14_days': 2,
        '30_days': 2,
      },
      order_value: {
        '7_days': 59.95,
        '14_days': 119.9,
        '30_days': 119.9,
      },
      percent_order_value: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
    trackings: {
      synced: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
    products: {
      synced: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
  },
  {
    id: '689c4fcf-0571-460d-a16b-5733981bb7e9',
    active_status: 'Y',
    status: 'Error',
    integration: {
      id: '1ed1f116-8527-6bfa-93c1-0605e1fd6890',
      name: 'Maropost x Bunnings (Mirakl)',
      logo: 'https://flow-public.nimbuspop.com/flow-apps/maropost_commerce_cloud__formerly_neto_.png',
    },
    orders: {
      synced: {
        '7_days': 1,
        '14_days': 2,
        '30_days': 2,
      },
      order_value: {
        '7_days': 59.95,
        '14_days': 119.9,
        '30_days': 119.9,
      },
      percent_order_value: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
    trackings: {
      synced: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
    products: {
      synced: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
  },
  {
    id: '689c4fcf-0571-460d-a16b-5733981bb7e9',
    active_status: 'Y',
    status: 'Inactive',
    integration: {
      id: '1ed1f116-8527-6bfa-93c1-0605e1fd6890',
      name: 'Maropost x Bunnings (Mirakl)',
      logo: 'https://wordpress-631421-2579652.cloudwaysapps.com/wp-content/uploads/2022/07/25498408_1747271388901154_6198955593483881874_n.png',
    },
    orders: {
      synced: {
        '7_days': 1,
        '14_days': 2,
        '30_days': 2,
      },
      order_value: {
        '7_days': 59.95,
        '14_days': '',
        '30_days': 119.9,
      },
      percent_order_value: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
    trackings: {
      synced: {
        '7_days': 0,
        '14_days': '',
        '30_days': 0,
      },
      errors: 0,
    },
    products: {
      synced: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
  },
  {
    id: '689c4fcf-0571-460d-a16b-5733981bb7e9',
    active_status: 'Y',
    status: 'Inactive',
    integration: {
      id: '1ed1f116-8527-6bfa-93c1-0605e1fd6890',
      name: 'Maropost x Bunnings (Mirakl)',
      logo: 'https://wordpress-631421-2579652.cloudwaysapps.com/wp-content/uploads/2022/07/25498408_1747271388901154_6198955593483881874_n.png',
    },
    orders: {
      synced: {
        '7_days': 1,
        '14_days': 2,
        '30_days': 2,
      },
      order_value: {
        '7_days': '',
        '14_days': 119.9,
        '30_days': 119.9,
      },
      percent_order_value: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
    trackings: {
      synced: {
        '7_days': 0,
        '14_days': '',
        '30_days': 0,
      },
      errors: 0,
    },
    products: {
      synced: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
  },
  {
    id: '689c4fcf-0571-460d-a16b-5733981bb7e9',
    active_status: 'Y',
    status: 'Active',
    integration: {
      id: '1ed1f116-8527-6bfa-93c1-0605e1fd6890',
      name: 'Maropost x Bunnings (Mirakl)',
      logo: 'https://wordpress-631421-2579652.cloudwaysapps.com/wp-content/uploads/2022/07/25498408_1747271388901154_6198955593483881874_n.png',
    },
    orders: {
      synced: {
        '7_days': 1,
        '14_days': 2,
        '30_days': 2,
      },
      order_value: {
        '7_days': 59.95,
        '14_days': 119.9,
        '30_days': 119.9,
      },
      percent_order_value: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
    trackings: {
      synced: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
    products: {
      synced: {
        '7_days': 0,
        '14_days': 0,
        '30_days': 0,
      },
      errors: 0,
    },
  },
];
export const syncLogProducts = [
  {
    order_id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
    integration_instance: {
      id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
      name: 'pshomeandliving Bunnings',
    },
    integration: {
      id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
      name: 'Maropost x Bunnings',
      logo: 'https://yt3.ggpht.com/CncRKbJ-wpCDDk8YbWA7EOSL9MFkgdQVX3PtIzwdu5MWDMgNg2LY3tAwB0P1bjAyAYZ6M4a4SQ=s900-c-k-c0x00ffffff-no-rj',
    },
    date_created: '22-12-2019 10:15 PM',
    date_updated: '12/01/2023',
    log: {
      status: 'Active',
      action_required: '',
      lifecycle: 'Awaiting Dispatch',
      history: [
        {
          sync_id: '148',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Retrieved order C-A1293428 from Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '149',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message:
            'Synced order C-A1293428 from Mirakl Bunnings to Maropost N21511',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '150',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced order payment for N21511',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '151',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Retrieved tracking data for N21511 from Maropost',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
      ],
    },
    source: {
      platform: 'maropost',
      order_id: 'N21511',
      status: 'Dispatched',
    },
    channel: {
      platform: 'mirakl_bunnings',
      order_id: 'C-A1293428',
      status: 'Shipped',
    },
    customer_name: 'John Smith',
    orderlines: [
      {
        sku: '7024',
        name: 'Wall Clock White',
        qty: '1',
        shipped_qty: '1',
        shipping_service: 'Australia Post Express',
        carrier: 'Australia Post',
        carrier_tracking: 'AP3959230202484',
        carrier_tracking_status: 'Out for delivery',
        success: true,
        errors: [],
        warnings: [],
      },
      {
        sku: '7025',
        name: 'Wall Clock Blue',
        qty: '1',
        shipped_qty: '1',
        shipping_service: 'Australia Post Express',
        carrier: 'Australia Post',
        carrier_tracking: 'AP3959230209239',
        carrier_tracking_status: 'Out for delivery',
        success: true,
        errors: [],
        warnings: [],
      },
    ],
  },
  {
    order_id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
    integration_instance: {
      id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
      name: 'pshomeandliving Bunnings',
    },
    integration: {
      id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
      name: 'Maropost x Bunnings',
      logo: 'https://yt3.ggpht.com/CncRKbJ-wpCDDk8YbWA7EOSL9MFkgdQVX3PtIzwdu5MWDMgNg2LY3tAwB0P1bjAyAYZ6M4a4SQ=s900-c-k-c0x00ffffff-no-rj',
    },
    date_created: '22-12-2019 10:15 PM',
    date_updated: '12/01/2023',
    log: {
      status: 'Warning',
      action_required: '',
      lifecycle: 'Completed',
      history: [
        {
          sync_id: '148',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Retrieved order C-A1293428 from Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '149',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message:
            'Synced order C-A1293428 from Mirakl Bunnings to Maropost N21511',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '150',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced order payment for N21511',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '151',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Retrieved tracking data for N21511 from Maropost',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
      ],
    },
    source: {
      platform: 'maropost',
      order_id: 'N21511',
      status: 'Dispatched',
    },
    channel: {
      platform: 'mirakl_bunnings',
      order_id: 'C-A1293428',
      status: 'Pending Shipment',
    },
    customer_name: 'John Smith',
    orderlines: [
      {
        sku: '7025',
        name: 'Wall Clock Blue',
        qty: '1',
        shipped_qty: '1',
        shipping_service: 'Australia Post Express',
        carrier: 'Australia Post',
        carrier_tracking: 'AP3959230209239',
        carrier_tracking_status: 'Out for delivery',
        success: true,
        errors: [],
        warnings: [],
      },
    ],
  },
  {
    order_id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
    integration_instance: {
      id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
      name: 'pshomeandliving Bunnings',
    },
    integration: {
      id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
      name: 'Maropost x Bunnings',
      logo: 'https://wordpress-631421-2579652.cloudwaysapps.com/wp-content/uploads/2022/07/90431683_10158311076699180_2657409051876392960_n.png',
    },
    date_created: '22-12-2019 10:15 PM',
    date_updated: '12/01/2023',
    log: {
      status: 'Error',
      action_required: '',
      lifecycle: 'Syncing to Source',
      history: [
        {
          sync_id: '148',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Retrieved order C-A1293428 from Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '149',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message:
            'Synced order C-A1293428 from Mirakl Bunnings to Maropost N21511',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '150',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced order payment for N21511',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '151',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Retrieved tracking data for N21511 from Maropost',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
      ],
    },
    source: {
      platform: 'maropost',
      order_id: 'N21511',
      status: 'Dispatched',
    },
    channel: {
      platform: 'mirakl_bunnings',
      order_id: 'C-A1293428',
      status: 'Pending Shipment',
    },
    customer_name: 'John Smith',
    orderlines: [
      {
        sku: '7024',
        name: 'Wall Clock White',
        qty: '1',
        shipped_qty: '1',
        shipping_service: 'Australia Post Express',
        carrier: 'Australia Post',
        carrier_tracking: 'AP3959230202484',
        carrier_tracking_status: 'Out for delivery',
        success: true,
        errors: [],
        warnings: [],
      },
      {
        sku: '7025',
        name: 'Wall Clock Blue',
        qty: '1',
        shipped_qty: '1',
        shipping_service: 'Australia Post Express',
        carrier: 'Australia Post',
        carrier_tracking: 'AP3959230209239',
        carrier_tracking_status: 'Out for delivery',
        success: true,
        errors: [],
        warnings: [],
      },
    ],
  },
  {
    order_id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
    integration_instance: {
      id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
      name: 'pshomeandliving Bunnings',
    },
    integration: {
      id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
      name: 'Maropost x Bunnings',
      logo: 'https://wordpress-631421-2579652.cloudwaysapps.com/wp-content/uploads/2022/07/90431683_10158311076699180_2657409051876392960_n.png',
    },
    date_created: '22-12-2019 10:15 PM',
    date_updated: '12/01/2023',
    log: {
      status: 'Warning',
      action_required: '',
      lifecycle: 'Syncing to Source',
      history: [
        {
          sync_id: '148',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Retrieved order C-A1293428 from Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '149',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message:
            'Synced order C-A1293428 from Mirakl Bunnings to Maropost N21511',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '150',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced order payment for N21511',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '151',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Retrieved tracking data for N21511 from Maropost',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
      ],
    },
    source: {
      platform: 'maropost',
      order_id: 'N21511',
      status: 'Dispatched',
    },
    channel: {
      platform: 'mirakl_bunnings',
      order_id: 'C-A1293428',
      status: 'Pending Shipment',
    },
    customer_name: 'John Smith',
    orderlines: [
      {
        sku: '7025',
        name: 'Wall Clock Blue',
        qty: '1',
        shipped_qty: '1',
        shipping_service: 'Australia Post Express',
        carrier: 'Australia Post',
        carrier_tracking: 'AP3959230209239',
        carrier_tracking_status: 'Out for delivery',
        success: true,
        errors: [],
        warnings: [],
      },
    ],
  },
  {
    order_id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
    integration_instance: {
      id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
      name: 'pshomeandliving Bunnings',
    },
    integration: {
      id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
      name: 'Maropost x Bunnings',
      logo: 'https://wordpress-631421-2579652.cloudwaysapps.com/wp-content/uploads/2022/07/90431683_10158311076699180_2657409051876392960_n.png',
    },
    date_created: '22-12-2019 10:15 PM',
    date_updated: '12/01/2023',
    log: {
      status: 'Warning',
      action_required: '',
      lifecycle: 'Syncing to Source',
      history: [
        {
          sync_id: '148',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Retrieved order C-A1293428 from Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '149',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message:
            'Synced order C-A1293428 from Mirakl Bunnings to Maropost N21511',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '150',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced order payment for N21511',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '151',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Retrieved tracking data for N21511 from Maropost',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
      ],
    },
    source: {
      platform: 'maropost',
      order_id: 'N21511',
      status: 'Dispatched',
    },
    channel: {
      platform: 'mirakl_bunnings',
      order_id: 'C-A1293428',
      status: 'Pending Shipment',
    },
    customer_name: 'John Smith',
    orderlines: [
      {
        sku: '7025',
        name: 'Wall Clock Blue',
        qty: '1',
        shipped_qty: '1',
        shipping_service: 'Australia Post Express',
        carrier: 'Australia Post',
        carrier_tracking: 'AP3959230209239',
        carrier_tracking_status: 'Out for delivery',
        success: true,
        errors: [],
        warnings: [],
      },
    ],
  },
  {
    order_id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
    integration_instance: {
      id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
      name: 'pshomeandliving Bunnings',
    },
    integration: {
      id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
      name: 'Maropost x Bunnings',
      logo: 'https://wordpress-631421-2579652.cloudwaysapps.com/wp-content/uploads/2022/07/90431683_10158311076699180_2657409051876392960_n.png',
    },
    date_created: '22-12-2019 10:15 PM',
    date_updated: '12/01/2023',
    log: {
      status: 'Error',
      action_required: '',
      lifecycle: 'Syncing to Source',
      history: [
        {
          sync_id: '148',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Retrieved order C-A1293428 from Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '149',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message:
            'Synced order C-A1293428 from Mirakl Bunnings to Maropost N21511',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '150',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced order payment for N21511',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '151',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Retrieved tracking data for N21511 from Maropost',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
      ],
    },
    source: {
      platform: 'maropost',
      order_id: 'N21511',
      status: 'Dispatched',
    },
    channel: {
      platform: 'mirakl_bunnings',
      order_id: 'C-A1293428',
      status: 'Pending Shipment',
    },
    customer_name: 'John Smith',
    orderlines: [
      {
        sku: '7025',
        name: 'Wall Clock Blue',
        qty: '1',
        shipped_qty: '1',
        shipping_service: 'Australia Post Express',
        carrier: 'Australia Post',
        carrier_tracking: 'AP3959230209239',
        carrier_tracking_status: 'Out for delivery',
        success: true,
        errors: [],
        warnings: [],
      },
    ],
  },
  {
    order_id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
    integration_instance: {
      id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
      name: 'pshomeandliving Bunnings',
    },
    integration: {
      id: '1ed1f118-421d-6a88-8f0a-0605e1fd6890',
      name: 'Maropost x Bunnings',
      logo: 'https://wordpress-631421-2579652.cloudwaysapps.com/wp-content/uploads/2022/07/90431683_10158311076699180_2657409051876392960_n.png',
    },
    date_created: '22-12-2019 10:15 PM',
    date_updated: '12/01/2023',
    log: {
      status: 'Active',
      action_required: '',
      lifecycle: 'Syncing to Source',
      history: [
        {
          sync_id: '148',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Retrieved order C-A1293428 from Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '149',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message:
            'Synced order C-A1293428 from Mirakl Bunnings to Maropost N21511',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '150',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced order payment for N21511',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '151',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Retrieved tracking data for N21511 from Maropost',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '152',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Synced tracking data to Mirakl Bunnings',
          errors: [],
          warnings: [],
        },
        {
          sync_id: '153',
          date: '12/01/2023 2:02:21 PM',
          result: 'Success',
          message: 'Sync Lifecycle updated to Completed',
          errors: [],
          warnings: [],
        },
      ],
    },
    source: {
      platform: 'maropost',
      order_id: 'N21511',
      status: 'Dispatched',
    },
    channel: {
      platform: 'mirakl_bunnings',
      order_id: 'C-A1293428',
      status: 'Pending Shipment',
    },
    customer_name: 'John Smith',
    orderlines: [
      {
        sku: '7025',
        name: 'Wall Clock Blue',
        qty: '1',
        shipped_qty: '1',
        shipping_service: 'Australia Post Express',
        carrier: 'Australia Post',
        carrier_tracking: 'AP3959230209239',
        carrier_tracking_status: 'Out for delivery',
        success: true,
        errors: [],
        warnings: [],
      },
    ],
  },
];

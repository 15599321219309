import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';
import { Pagination } from 'app/layout/common/grid/grid.types';
import { appConfig } from 'app/core/config/app.config';
import { EcommifyApiResponse } from 'app/core/api/api.types';

import { LocalStorageUtils } from 'app/core/common/local-storage.utils';
import { GridUtils } from '../../layout/common/grid/grid.utils';
import {
  ProductGroupForm,
  ProductGroupsResponse,
} from './product-groups.types';

@Injectable({
  providedIn: 'root',
})
export class ProductGroupsService {
  // Private
  private _config = appConfig;
  private _productGroupsData: BehaviorSubject<ProductGroupsResponse[] | null> =
    new BehaviorSubject(null);
  private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(
    null
  );

  constructor(private _httpClient: HttpClient) {}

  // Getter for Product Groups Data Dashboard
  get ProductGroupsData$(): Observable<ProductGroupsResponse[]> {
    return this._productGroupsData.asObservable();
  }

  // Getter for pagination
  get pagination$(): Observable<Pagination> {
    return this._pagination.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Get IntegrationStatus Dashboard Data
   * @param page , @param size, @param sort, @param order, @param search // TBD
   */
  getProductGroupsData(
    page: number = 0,
    size: number = 10000,
    search: string = ''
  ): Observable<EcommifyApiResponse<ProductGroupsResponse>> {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;

    let body: any = {
      page: '' + page,
      size: '' + size,
    };

    if (search) {
      body = { ...body, search };
    }
    return this._httpClient
      .post<EcommifyApiResponse<ProductGroupsResponse>>(
        `${api}/${companyID}/products/group/search`,
        body
      )
      .pipe(
        tap((response: any) => {
          const { result } = response;
          const pagination = GridUtils.getPagination(result);
          this._pagination.next(pagination);
        }),
        catchError((_error: any) => {
          return of(null);
        })
      );
  }

  createProductGroup(
    body: ProductGroupForm,
    groupId: any = null
  ): Observable<EcommifyApiResponse<ProductGroupsResponse>> {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;
    const URL = groupId
      ? `${api}/${companyID}/products/group/${groupId}`
      : `${api}/${companyID}/products/group`;
    const Method = groupId ? 'put' : 'post';

    return this._httpClient[Method]<EcommifyApiResponse<ProductGroupsResponse>>(
      URL,
      body
    ).pipe(
      catchError((_error: any) => {
        return of(null);
      })
    );
  }

  deleteGroup(
    groupId: any = null
  ): Observable<EcommifyApiResponse<ProductGroupsResponse>> {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;
    return this._httpClient
      .delete<EcommifyApiResponse<ProductGroupsResponse>>(
        `${api}/${companyID}/products/group/${groupId}`
      )
      .pipe(
        catchError((_error: any) => {
          return of(null);
        })
      );
  }
}

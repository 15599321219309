import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Attribute_Types,
  OperatorOptions,
  CharacterOperatorOptions,
  ProductAttributeTypes,
  CombinedOperatorOptionsCharacterOperatorOptions,
  DateOperatorOptions,
  MultiselectOperatorOptions,
  BooleanOperatorOptions,
} from 'app/core/config/app.config';
import { Pagination } from 'app/layout/common/grid/grid.types';
import { ProductAttribute } from 'app/shared/intefaces/product.types';
import { SnackbarService } from 'app/services/snackbar.service';
import { isNumber } from 'lodash';
import moment from 'moment';
import { Subject } from 'rxjs';
// import { ProductsService } from '../../../modules/user/sync-logs/products/products.service';

interface Form {
  attribute: any;
  operator: any;
  value: any;
  dateFilterType?: boolean | string;
  filterByCharCount: boolean;
  filterByTime: boolean;
  multiSelectType: boolean;
  isAllCategories: boolean;
}
@Component({
  selector: 'eco-attribute-filter',
  templateUrl: './attribute-filter.component.html',
  styleUrls: ['./attribute-filter.component.scss'],
})
export class AttributeFilterComponent implements OnInit {
  @Input('selectedFilter') set _filter(value: Array<any>) {
    this.filter = this.convertBooleanToNumbers(value, 'boolean', {
      1: true,
      0: false,
    });
    // console.log(value);
    this.filter = value || [];
  }

  @Input() title: string = 'Attributes';
  @Input() titleClass: string = '';

  @Output() onPanelClose: EventEmitter<any> = new EventEmitter();
  @Output() onApplyFilter: EventEmitter<any> = new EventEmitter();
  @Output() selectedFilterChange: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteFilter: EventEmitter<any> = new EventEmitter();
  @Output() onClearFilter: EventEmitter<any> = new EventEmitter();
  @Output() onPanelOpen: EventEmitter<any> = new EventEmitter();

  //   private _unsubscribeAll: Subject<any> = new Subject<any>();
  @Input() productAttributes: ProductAttribute[] = [];
  pagination: Pagination;

  operatorOptions: any[] = CharacterOperatorOptions;
  dateOperatorOptions: any[] = DateOperatorOptions;
  multiselectOperatorOptions: any[] = MultiselectOperatorOptions;
  booleanOperatorOptions: any[] = BooleanOperatorOptions;
  dateFilterType: any[] = [
    {
      label: 'Custom Date',
      id: 'custom_date',
    },
    {
      label: 'Relative Date',
      id: 'relative_date',
    },
  ];

  form: Form = {
    attribute: '',
    operator: {},
    value: '',
    dateFilterType: false,
    filterByCharCount: false,
    filterByTime: false,
    multiSelectType: false,
    isAllCategories: true,
  };
  attribute_type = ProductAttributeTypes;
  selectedFilterBlockIndex: number;
  editBlockIndex: number;
  editFilterIndex: number;
  characterCountCheckboxModel: boolean;
  isTimeMatchCheckboxModel: boolean;
  hasDecimalError: boolean = false;
  AF_AA_searchQuery: string;
  isCategories: boolean = false;
  isExpanded: boolean = true;
  filter: Array<any> = [];
  //   preSelectedcategories: any[] = [];
  isEditPanelOpen: boolean = false;
  panelOpenFor: 'ADD' | 'EDIT' = 'ADD';
  selectedCategories: any[] = [];
  constructor(private _snackbarService: SnackbarService) {}

  ngOnInit(): void {
    this.productAttributes.push({
      label: 'Categories',
      type: 'category_multiselect',
      isFixedAttribute: true,
      code: 'category',
      description: null,
      attribute_type: 'category_multiselect',
    });
  }

  applyColumnFilter() {
    if (this.panelOpenFor == 'ADD') {
      const body = this.validateAndUpdateForm(this.form);
      if (body != null && body != undefined) {
        if (this.filter.length <= 0) {
          // Initial first filter added
          this.filter.push([{ ...body }]);
          this.applyFilter(this.filter);
        } else {
          if (
            this.selectedFilterBlockIndex != null &&
            this.selectedFilterBlockIndex != undefined &&
            isNumber(this.selectedFilterBlockIndex)
          ) {
            // AND Button press
            this.filter[this.selectedFilterBlockIndex].push(body);
            this.applyFilter(this.filter);
          } else {
            // OR Button press
            this.filter.push([{ ...body }]);
            this.applyFilter(this.filter);
          }
        }
      }
    } else if (this.panelOpenFor == 'EDIT') {
      const body = this.validateAndUpdateForm(this.form);
      if (body) {
        if (
          this.editBlockIndex != undefined &&
          this.editBlockIndex != null &&
          this.editFilterIndex != undefined &&
          this.editFilterIndex != null
        ) {
          this.filter[this.editBlockIndex][this.editFilterIndex] = {
            ...body,
          };
          this.applyFilter(this.filter);
        }
      }
    }
  }

  validateAndUpdateForm(form: Form) {
    const obj = JSON.parse(JSON.stringify(form));
    // if (
    //   (obj?.operator?.value == 'exists' || obj?.operator?.value == '!exists') &&
    //   [this.attribute_type.short_text].indexOf(this.form.attribute.type) != -1
    // ) {
    //   return obj;
    // }
    // if (obj?.operator?.value != 'exists' && obj?.operator?.value != '!exists')
    //   if (
    //     !obj.attribute ||
    //     !obj.operator ||
    //     obj.value == undefined ||
    //     obj.value == null ||
    //     (typeof obj.value != 'boolean' && obj.value == '')
    //   ) {
    //     this._snackbarService.showError('Fill out all the necessary fields');
    //     return null;
    //   }
    switch (obj.attribute.type) {
      case this.attribute_type.category_multiselect:
        if (!obj.value?.length) {
          obj.value = null;
          obj.operator = { value: 'exists', label: 'is defined' };
        }
        if (
          obj.operator.value === 'exists' ||
          obj.operator.value === '!exists'
        ) {
          obj.value = null;
        }
        break;
      case this.attribute_type.dropdown:
        if (
          obj.value === null ||
          obj.value === undefined ||
          !obj.value?.length
        ) {
          this._snackbarService.showError('Fill out all the necessary fields');
          return null;
        }
        break;

      case this.attribute_type.short_text:
        if (
          (obj.value === null ||
            obj.value === undefined ||
            !obj.value?.length) &&
          obj.filterByCharCount &&
          obj.operator.value !== 'exists' &&
          obj.operator.value !== '!exists'
        ) {
          this._snackbarService.showError('Fill out all the necessary fields');
          return null;
        } else {
          if (
            obj.operator.value === 'exists' ||
            obj.operator.value === '!exists'
          )
            obj.value = '';
        }
        break;

      case this.attribute_type.media_single:
        obj.value = '';
        break;
      case this.attribute_type.remote_gallery:
        obj.value = '';
        break;

      case this.attribute_type.decimal:
        if (
          obj.value === null ||
          obj.value === undefined ||
          !String(obj.value)?.length
        ) {
          this._snackbarService.showError('Fill out all the necessary fields');
          return null;
        }
        break;
      case this.attribute_type.boolean:
        if (
          obj.value === null ||
          obj.value === undefined ||
          !String(obj.value)?.length
        ) {
          this._snackbarService.showError('Fill out all the necessary fields');
          return null;
        }
        break;
      case this.attribute_type.html:
        if (
          (obj.value === null ||
            obj.value === undefined ||
            (typeof Object.values === 'string' && !obj.value?.length)) &&
          obj.operator.value !== 'exists' &&
          obj.operator.value !== '!exists'
        ) {
          this._snackbarService.showError('Fill out all the necessary fields');
          return null;
        }
        break;

      case this.attribute_type.date:
        if (obj.dateFilterType === 'custom_date')
          obj.value = moment(obj.value).utc().toISOString();
        break;

      default:
        break;
    }
    return obj;
  }

  hydrateEditItem(form: Form) {
    const obj = JSON.parse(JSON.stringify(form));
    switch (obj.attribute.type) {
      case this.attribute_type.date:
        if (obj.dateFilterType == 'custom_date')
          obj.value = new Date(moment.utc(obj.value).local().toISOString());
        if (obj.dateFilterType == 'custom_date')
          obj.value = new Date(moment.utc(obj.value).local().toISOString());

        break;
      case this.attribute_type.category_multiselect:
        if (!String(obj.value)?.length) {
          obj.value = null;
        }
        break;
    }
    return obj;
  }

  openEditPanel(
    editItem: any,
    blockIndex: number = null,
    filterIndex: number = null
  ) {
    this.panelOpenFor = 'EDIT';
    const body = this.hydrateEditItem(editItem);
    this.form = body;
    this.editBlockIndex = blockIndex;
    this.editFilterIndex = filterIndex;
    this.isEditPanelOpen = true;
    this.onPanelOpen.emit();
  }

  onCategoriesEdit() {
    // this.preSelectedcategories = this.form.value?.length ? this.form.value : [];
    // this.selectedCategories = this.form.value?.length ? this.form.value : [];
    this.isCategories = true;
  }

  addNewFilter(blockIndex: number = null) {
    this.panelOpenFor = 'ADD';
    this.selectedFilterBlockIndex = blockIndex;
    this.isEditPanelOpen = true;
    this.onPanelOpen.emit();
  }

  isIntegerNumber(input, control) {
    if (!input) return;
    input = input.toString();
    const integerPattern = /^[-+]?\d+$/;
    if (integerPattern.test(input)) {
      control.hasError = false;
    } else {
      control.hasError = true;
    }
  }

  isPositiveIntegerNumber(input, control) {
    if (!input) return;
    input = input.toString();
    const positiveIntegerPattern = /^\d+$/;
    if (positiveIntegerPattern.test(input) && parseInt(input) > 0) {
      control.hasError = false;
      return true;
    } else {
      control.hasError = true;
      return false;
    }
  }

  deleteFilterItem(blockIndex: number = null, filterIndex: number = null) {
    if (this.filter.length == 1) {
      if (this.filter[blockIndex].length == 1) {
        this.filter.splice(blockIndex, 1);
      } else {
        this.filter[blockIndex].splice(filterIndex, 1);
      }
    } else {
      if (this.filter[blockIndex].length == 1) {
        this.filter.splice(blockIndex, 1);
      } else {
        this.filter[blockIndex].splice(filterIndex, 1);
      }
    }
    let object = this.buildFilterJSON(this.filter);
    let filter = this.filter;
    this.onDeleteFilter.emit({ object, filter });
  }

  resetForm() {
    this.form = {
      attribute: '',
      operator: '',
      value: '',
      dateFilterType: this.dateFilterType[0],
      filterByCharCount: false,
      filterByTime: false,
      isAllCategories: false,
      multiSelectType: false,
    };
  }

  applyFilter(filter: any = null) {
    this.resetForm();
    this.selectedFilterBlockIndex = null;
    this.isEditPanelOpen = false;
    let object = null;
    if (filter) {
      object = this.buildFilterJSON(filter);
    }
    this.selectedFilterChange.emit(filter);
    this.onApplyFilter.emit({ object, filter });
  }

  getAttributeType(item) {
    if (item?.attribute?.type) return item?.attribute?.type;
  }

  handleBackClick() {
    this.resetForm();
    this.selectedCategories = [];
    // this.preSelectedcategories = [];
    this.selectedFilterBlockIndex = null;
    this.isEditPanelOpen = false;
    this.onPanelClose.emit();
  }

  clearFilter() {
    this.filter = [];
    this.onClearFilter.emit(this.buildFilterJSON(this.filter));
  }

  characterCountCheckboxChange(value) {
    if (value == true) {
      this.operatorOptions = OperatorOptions;
      this.form.operator = this.operatorOptions.find(
        item => item.value == 'eq'
      );
    } else {
      this.operatorOptions = CharacterOperatorOptions;
      this.form.operator = this.operatorOptions.find(
        item => item.value == 'exists'
      );
    }
  }

  filterByTimeCheckboxChange(value) {
    this.form.value = null;
  }

  dateFilterTypeChange() {
    this.form.filterByTime = false;
    this.form.dateFilterType == 'relative_date'
      ? (this.form.value = 7)
      : (this.form.value = '');
  }

  compareAttribute(
    option1: ProductAttribute,
    option2: ProductAttribute
  ): boolean {
    return option1 && option2
      ? option1?.code === option2?.code
      : option1 === option2;
  }

  compareOperator(option1: any, option2: any): boolean {
    return option1 && option2
      ? option1?.value === option2?.value
      : option1 === option2;
  }

  handleAttributeSelect() {
    this.resetForm;
    this.form = {
      attribute: this.form.attribute,
      operator: '',
      value: '',
      dateFilterType: this.dateFilterType[0],
      filterByCharCount: false,
      filterByTime: false,
      isAllCategories: false,
      multiSelectType: false,
    };
    if (
      this.form?.attribute &&
      this.form?.attribute?.type == this.attribute_type.date
    ) {
      this.form.dateFilterType = this.dateFilterType[0];
    } else {
      this.operatorOptions = CombinedOperatorOptionsCharacterOperatorOptions;
      //   this.form.operator = this.operatorOptions.find(
      //     item => item.value == 'eq'
      //   );
      //   this.form.operator = this.operatorOptions[0].value;
      this.form.operator = this.operatorOptions[0];
      this.form.dateFilterType = false;
    }
    if (
      [this.attribute_type.short_text].indexOf(this.form.attribute.type) != -1
    ) {
      if (this.form.filterByCharCount) {
        this.operatorOptions = OperatorOptions;
        // this.form.operator = this.operatorOptions.find(
        //   item => item.value == 'eq'
        // );
        this.form.operator = this.operatorOptions[0];
      }
    }
  }

  buildFilterJSON(filter) {
    // console.log(filter);
    let object = null;
    object = JSON.parse(JSON.stringify(filter));
    object = object.map(block => {
      return block.map((filterItem: any) => {
        filterItem.operator = filterItem.operator.value;
        if (filterItem.attribute.code === 'category') {
          filterItem.field = 'category';
          //   filterItem.value = this.selectedCategories.map(elem => {
          //     return elem.code;
          //   });
          delete filterItem?.attribute;
        } else {
          filterItem.field =
            filterItem.attribute.attribute_type == Attribute_Types.userDefined
              ? `attributes.${filterItem.attribute.code}`
              : filterItem.attribute.code;
          delete filterItem?.attribute;
        }

        return filterItem;
      });
    });
    return object;
  }

  convertBooleanToNumbers(
    arr: any,
    checkType: 'boolean' | 'value' = 'boolean',
    values: any = {}
  ) {
    if (!Array.isArray(arr)) return arr;
    return arr.map(elem => {
      if (Array.isArray(elem)) {
        return this.convertBooleanToNumbers(elem, checkType, values);
      } else if (typeof elem === 'object' && elem !== null) {
        return Object.entries(elem).reduce((acc, [key, value]) => {
          if (checkType == 'boolean') {
            if (typeof value === 'boolean') {
              acc[key] = value ? 1 : 0;
            } else {
              acc[key] = this.convertBooleanToNumbers(value, checkType, values);
            }
          }
          if (checkType == 'value') {
            if (typeof value === 'string' && value in values) {
              acc[key] = values[value];
            } else {
              acc[key] = this.convertBooleanToNumbers(value, checkType, values);
            }
          }
          return acc;
        }, {});
      } else {
        return elem;
      }
    });
  }

  /**
   * Categories Modal functions
   */

  onCategoriesSave(val: any[]) {
    // this.selectedCategories = val;
    this.form.value = val.map(elem => {
      return elem.code;
    });
    this.isCategories = false;
  }
}

<!-- <div>{{}}</div> -->
<div
  class="product-category-tree-node"
  [ngClass]="{ 'ml-24px': isChildren }"
  *ngIf="categories?.length">
  <!-- <div class="category-select-tree"> -->
  <!-- <div class="categories-column-header">
      <h3>Available categories</h3>
      <span class="fs-12 eco-clickable center">Collapse all groups</span>
    </div> -->
  <div class="mt-16px category-tree">
    <div
      class="category-tree-list"
      *ngFor="let category of categories; let last = last"
      [ngClass]="{ 'is-children': isChildren, 'border-0': last }">
      <div class="category-node" [ngClass]="{ 'is-children': isChildren }">
        <div>
          <mat-checkbox
            color="primary"
            class="example-margin"
            [(ngModel)]="category.isSelected"
            [checked]="category.isSelected"
            (change)="onCategorySelect(category)"></mat-checkbox>
        </div>
        <div class="center" *ngIf="category.has_children">
          <i
            class="pi"
            [ngClass]="{
              'pi-chevron-down': category.showChildren,
              'pi-chevron-right': !category.showChildren
            }"
            (click)="onLoadChildren(category)"></i>
        </div>
        <div class="ml-8px center fs-12">
          {{ category.label }}
        </div>
      </div>
      <app-product-category-tree-node
        *ngIf="category.showChildren"
        [isChildren]="true"
        [categories]="category.children"
        [selectedCategories]="selectedCategories"
        (OnCategorySelect)="
          onCategorySelect($event)
        "></app-product-category-tree-node>
    </div>
  </div>
  <!-- </div> -->
</div>

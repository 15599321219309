import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Message } from 'app/layout/common/messages/messages.types';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { WebsocketService, AdminService, LocalStorageService } from '@services';
import moment from 'moment';

@Component({
  selector: 'messages',
  templateUrl: './messages.component.html',
  exportAs: 'messages',
})
export class MessagesComponent implements OnInit, OnDestroy {
  @ViewChild('messagesOrigin') private _messagesOrigin: MatButton;
  @ViewChild('messagesPanel') private _messagesPanel: TemplateRef<any>;

  messages: Message[];
  unreadCount: number = 0;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  //   private socketSubscription: Subscription;

  /**
   * Constructor
   */
  constructor(
    private _messagesService: MessagesService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    // private websocketService: WebsocketService,
    private locaStorageService: LocalStorageService
  ) {
    // this.establishConnections();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.getAll();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    console.log('Destroyed');
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    // this.websocketService.closeConnection();
    // this.socketSubscription.unsubscribe();

    // Dispose the overlay
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open the messages panel
   */
  openPanel(): void {
    // Return if the messages panel or its origin is not defined
    if (!this._messagesPanel || !this._messagesOrigin) {
      return;
    }

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(
      new TemplatePortal(this._messagesPanel, this._viewContainerRef)
    );
  }

  /**
   * Close the messages panel
   */
  closePanel(): void {
    this._overlayRef.detach();
  }

  /**
   * Mark all messages as read
   */
  markAllAsRead(): void {
    // Mark all as read
    this._messagesService.markAllAsRead().subscribe();
  }

  /**
   * Toggle read status of the given message
   */
  toggleRead(message: Message): void {
    // Toggle the read status
    message.read = !message.read;

    // Update the message
    this._messagesService.update(message.id, message).subscribe();
  }

  /**
   * Delete the given message
   */
  delete(message: Message): void {
    // Delete the message
    this._messagesService.delete(message.id).subscribe();
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create the overlay
   */
  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._messagesOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  private getFormattedDate(dateTime: string): string {
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const UserTimeZone = this.locaStorageService.userTimezone || localTimezone;
    const DateInTimezone = moment(dateTime).tz(UserTimeZone);
    const today = moment().tz(UserTimeZone);
    const yesterday = moment().tz(UserTimeZone).subtract(1, 'days');

    if (DateInTimezone.isSame(today, 'day')) {
      return `Today, ${DateInTimezone.format('h:mm a')}`;
    } else if (DateInTimezone.isSame(yesterday, 'day')) {
      return `Yesterday, ${DateInTimezone.format('h:mm a')}`;
    } else {
      return DateInTimezone.format('MMM DD, h:mm a ');
    }
  }
  /**
   * Calculate the unread count
   *
   * @private
   */
  private _calculateUnreadCount(): void {
    let count = 0;

    if (this.messages && this.messages.length) {
      count = this.messages.filter(message => !message.read).length;
    }

    this.unreadCount = count;
  }

  /**
   * API's
   */
  private getAll() {
    const paramsObj = {
      size: 1000,
      page: 0,
      order: 'desc',
      sort: 'timestamp',
    };
    this._messagesService.getAlerts(paramsObj).subscribe((res: any) => {
      const result_info = res.result_info;
      this.messages = res.data.map(elem => {
        return {
          ...elem,
          formattedDate: this.getFormattedDate(elem.timestamp),
        };
      });
      this.unreadCount = result_info.total_records;
    });
  }

  private establishConnections() {
    // this.socketSubscription = this.websocketService
    //   .connect('wss://dev1.api.ecommify.io/v2/admin/alerts')
    //   .subscribe(
    //     (res: any) => {
    //       const result_info = res.result_info;
    //       this.messages = res.data;
    //       this.unreadCount = result_info.total_records;
    //     },
    //     error => {
    //       console.log('Socket Error');
    //     }
    //   );
  }
}

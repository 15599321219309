import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProductCategoryFilterComponent } from './product-category-filter.component';
import { ProductCategoryTreeNodeComponent } from './product-category-tree-node/product-category-tree-node.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTreeModule } from '@angular/material/tree';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

const LIB_MODULES = [
  MatCheckboxModule,
  MatIconModule,
  FuseLoadingBarModule,
  MatInputModule,
  MatFormFieldModule,
];
const COMPONENTS = [
  ProductCategoryFilterComponent,
  ProductCategoryTreeNodeComponent,
];
@NgModule({
  imports: [CommonModule, FormsModule, ...LIB_MODULES],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class ProductCategoryFilterModule {}

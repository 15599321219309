import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';
import { Pagination } from 'app/layout/common/grid/grid.types';
import { appConfig } from 'app/core/config/app.config';
import { EcommifyApiResponse } from 'app/core/api/api.types';

import { LocalStorageUtils } from 'app/core/common/local-storage.utils';
import { GridUtils } from 'app/layout/common/grid/grid.utils';
import {
  ProductAttribute,
  ProductAttributeListResponse,
} from '../shared/intefaces/product.types';

@Injectable({
  providedIn: 'root',
})
export class ProductAttributesService {
  private _config = appConfig;
  private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(
    null
  );
  private _productAttributeList: BehaviorSubject<ProductAttribute[] | null> =
    new BehaviorSubject(null);

  constructor(private _httpClient: HttpClient) {}

  /**
   * Getter Methods
   */

  get pagination$(): Observable<Pagination> {
    return this._pagination.asObservable();
  }

  get productAttributeList$(): Observable<ProductAttribute[]> {
    return this._productAttributeList.asObservable();
  }

  /**
   * API Methods
   */

  getProductAttributes(
    page: number = 0,
    size: number = 100,
    sort: string = null,
    order: 'asc' | 'desc' | '' | null = null,
    filter = null
  ): Observable<EcommifyApiResponse<ProductAttributeListResponse>> {
    const api = this._config?.apiConfig?.serviceUrlv1;
    const companyID = LocalStorageUtils.companyId;

    let body: any = {
      page: page,
      size: size,
    };
    if (sort && order) {
      body.sort = sort;
      body.order = order;
    }
    if (filter) {
      body = { ...body, ...filter };
    }

    return this._httpClient
      .post<EcommifyApiResponse<ProductAttributeListResponse>>(
        `${api}/${companyID}/products/attributes/search`,
        body
      )
      .pipe(
        tap(response => {
          const { result } = response;
          const pagination = GridUtils.getPagination(result);
          this._pagination.next(pagination);
          this._productAttributeList.next(result.product_attributes);
        }),
        catchError(error => {
          return of(null);
        })
      );
  }

  saveProductAttribute(body, attributeId: any = null) {
    const api = this._config?.apiConfig?.serviceUrlv1;
    const companyID = LocalStorageUtils.companyId;
    const URL = attributeId
      ? `${api}/${companyID}/products/attributes/${attributeId}`
      : `${api}/${companyID}/products/attributes`;
    const Method = attributeId ? 'put' : 'post';

    return this._httpClient[Method]<EcommifyApiResponse<any>>(URL, body).pipe(
      catchError(error => {
        return of(error);
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { appConfig } from 'app/core/config/app.config';
import { LocalStorageUtils } from 'app/core/common/local-storage.utils';
import { SnackbarService } from 'app/services/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ProductOrderService {
  // Private
  private _config = appConfig;
  /**
   * Constructor
   */
  constructor(
    private http: HttpClient,
    private _snackbarService: SnackbarService
  ) {}

  markBulkOrdersCompeleted(data) {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;
    return new Observable(observable => {
      this.http
        .put(`${api}/${companyID}/orders/mark-as-completed`, data)
        .subscribe({
          next: (res: any) => {
            if (res.success) {
              this._snackbarService.showSuccess(res.message);
              observable.next(res.result);
            } else {
              observable.error(res);
              this._snackbarService.showError(res.message);
            }
          },
        }),
        (err: any) => {
          this._snackbarService.showError(err.error.message);
          observable.error(err);
        };
    });
  }

  markBulkOrdersCancelled(data) {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;
    return new Observable(observable => {
      this.http
        .put(`${api}/${companyID}/orders/mark-as-cancelled`, data)
        .subscribe({
          next: (res: any) => {
            if (res.success) {
              this._snackbarService.showSuccess(res.message);
              // this.toastr.success({ detail: "Success", summary: res.message });
              observable.next(res.result);
            } else {
              observable.error(res);
              // this.toastr.error({ detail: "Error", summary: res.message });
              this._snackbarService.showError(res.message);
            }
          },
        }),
        (err: any) => {
          this._snackbarService.showError(err.error.message);
          observable.error(err);
        };
    });
  }

  resetSyncForBulkOrders(data) {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;
    return new Observable(observable => {
      this.http
        .put(`${api}/${companyID}/orders/reset-failed-attempts`, data)
        .subscribe({
          next: (res: any) => {
            if (res.success) {
              this._snackbarService.showSuccess(res.message);
              // this.toastr.success({ detail: "Success", summary: res.message });
              observable.next(res.result);
            } else {
              observable.error(res);
              // this.toastr.error({ detail: "Error", summary: res.message });
              this._snackbarService.showError(res.message);
            }
          },
        }),
        (err: any) => {
          this._snackbarService.showError(err.error.message);
          observable.error(err);
        };
    });
  }

  updateOrder(orderId, order) {
    const api = this._config?.apiConfig?.serviceUrlv1;
    const companyID = LocalStorageUtils.companyId;
    return new Observable(observable => {
      this.http.put(`${api}/${companyID}/orders/${orderId}`, order).subscribe({
        next: (res: any) => {
          if (res.success) {
            this._snackbarService.showSuccess('Order updated successfully');
            // this.toastr.success({ detail: "Success", summary: res.message });
            observable.next(res.result);
          } else {
            observable.error(res);
            // this.toastr.error({ detail: "Error", summary: res.message });
            this._snackbarService.showError(res.message);
          }
        },
      }),
        (err: any) => {
          this._snackbarService.showError(err.error.message);
          observable.error(err);
        };
    });
  }
}

<div class="attribute-filter">
  <!-- Main Panel Title-->
  <div class="flex mb-4 items-center" *ngIf="!isEditPanelOpen">
    <div class="grow">
      <span
        class="font-medium text-slate-500 text-[13px]"
        [ngClass]="titleClass"
        >{{ title }}
      </span>
    </div>
    <div *ngIf="filter.length <= 0">
      <button class="flex items-center" (click)="addNewFilter()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div *ngIf="filter.length > 0">
      <button (click)="isExpanded = !isExpanded">
        <mat-icon>{{ isExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
      </button>
    </div>
  </div>

  <!-- Expanded Panel -->
  <div
    *ngIf="!isEditPanelOpen && isExpanded"
    class="border-bottom-dashed mb-16px pb-16px">
    <div
      class=""
      *ngFor="
        let filterBlock of filter;
        let isLast = last;
        let blockIndex = index
      ">
      <div
        class="border border-solid filter-clauses cursor-pointer shadow-sm"
        *ngFor="let filterItem of filterBlock; let filterIndex = index">
        <div class="flex items-center">
          <div
            class="grow text-[11px]"
            (click)="openEditPanel(filterItem, blockIndex, filterIndex)">
            <span class="text-orange-400"
              >{{ filterItem.attribute.label }}
            </span>

            <span *ngIf="filterItem.operator"
              >{{ filterItem.operator.label }}
            </span>
            <span [ngSwitch]="filterItem?.attribute.type">
              <ng-container *ngSwitchCase="'category_multiselect'">
                ({{
                  filterItem?.value?.length > 0
                    ? filterItem?.value?.length
                    : 'All'
                }}
                {{
                  filterItem?.value?.length > 1 ? 'categories' : 'category'
                }})</ng-container
              >
              <ng-container *ngSwitchCase="'date'">
                <ng-container
                  *ngIf="
                    filterItem.dateFilterType === 'relative_date';
                    else showCustomDate
                  ">
                  last {{ filterItem.value }}
                  {{ filterItem.value > 1 ? 'Days' : 'Day' }}
                </ng-container>
                <ng-template #showCustomDate>
                  {{ filterItem.value | date: 'dd, MMM yyyy' }}
                  {{
                    filterItem.filterByTime
                      ? (filterItem.value | date: 'hh:mm a')
                      : ''
                  }}</ng-template
                >
              </ng-container>
              <ng-container *ngSwitchCase="'boolean'">
                {{ filterItem.value === 1 ? 'true' : 'false' }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ filterItem.value }}
              </ng-container>
            </span>

            <!-- <span
              *ngIf="filterItem.value != undefined && filterItem.value != null"
              >{{ filterItem.value }}
            </span> -->
          </div>
          <button class="" (click)="deleteFilterItem(blockIndex, filterIndex)">
            <i class="pi pi-times text-danger"></i>
          </button>
        </div>
      </div>
      <div class="flex gap-1 mt-8px">
        <!-- <span class="eco-clickable fs-12" (click)="addNewFilter(blockIndex)"
          >AND</span
        >
        <span
          class="eco-clickable fs-12"
          (click)="addNewFilter()"
          *ngIf="isLast"
          >OR</span
        > -->
        <button
          mat-button
          color="primary"
          class="link-button"
          (click)="addNewFilter(blockIndex)">
          AND
        </button>
        <button
          mat-button
          color="primary"
          class="link-button"
          *ngIf="isLast"
          (click)="addNewFilter()">
          OR
        </button>
      </div>

      <p class="or-divider py-1" *ngIf="!isLast">
        <span class="fs-12">OR</span>
      </p>
    </div>
  </div>

  <!-- Form Panel -->
  <div *ngIf="isEditPanelOpen">
    <div class="mb-[8px]">
      <button
        mat-button
        class="panel-back-arrow"
        (click)="handleBackClick(filter)">
        <mat-icon svgIcon="mat_outline:arrow_back"></mat-icon>
      </button>
    </div>

    <div>
      <div class="uppercase text-xs mb-1">Select Attribute</div>
      <mat-form-field appearance="fill" class="w-full thin-form-control">
        <mat-select
          [(ngModel)]="form.attribute"
          (selectionChange)="handleAttributeSelect()"
          [compareWith]="compareAttribute"
          placeholder="Select Attribute">
          <mat-select-trigger>
            <span class="custom">{{ form.attribute?.label }}</span>
          </mat-select-trigger>
          <input
            [(ngModel)]="AF_AA_searchQuery"
            class="p-3 border-b w-full mb-2 sticky top-0 bg-white z-50"
            placeholder="Search..." />
          <mat-option
            *ngFor="
              let attribute of productAttributes
                | search: 'label,type':AF_AA_searchQuery:'I'
            "
            [value]="attribute">
            <div class="flex items-center">
              <p class="mr-[8px] mb-0">{{ attribute?.label }}</p>
              <span class="text-slate-400 capitalize">{{
                attribute?.type === 'category_multiselect'
                  ? 'Multiselect'
                  : attribute?.type
              }}</span>
            </div>
          </mat-option>
          <!-- <mat-option [value]="{ label: 'Category', type: 'multiselect' }">
            <div class="flex items-center">
              <p class="mr-[8px] mb-0">Category</p>
              <span class="text-slate-400">Multiselect</span>
            </div>
          </mat-option> -->
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container *ngIf="form.attribute !== ''">
      <!-- Short Text -->
      <ng-container
        *ngIf="[attribute_type.short_text].indexOf(form.attribute.type) != -1">
        <mat-checkbox
          class="text-[13px] mb-[16px] w-full"
          color="primary"
          [(ngModel)]="form.filterByCharCount"
          (change)="characterCountCheckboxChange(form.filterByCharCount)"
          #characterCountCheckbox
          >Character count</mat-checkbox
        >
      </ng-container>

      <!-- Date -->
      <ng-container
        *ngIf="[attribute_type.date].indexOf(form.attribute.type) != -1">
        <mat-form-field appearance="fill" class="w-full thin-form-control">
          <mat-select
            [(ngModel)]="form.dateFilterType"
            (selectionChange)="dateFilterTypeChange()"
            placeholder="Date type">
            <mat-option
              *ngFor="let option of dateFilterType"
              [value]="option.id"
              class="capitalize">
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <mat-form-field appearance="fill" class="w-full thin-form-control">
        <mat-select
          [(ngModel)]="form.operator"
          [compareWith]="compareOperator"
          placeholder="Select Operator">
          <span [ngSwitch]="form.attribute.type">
            <ng-container *ngSwitchCase="'date'">
              <mat-option
                *ngFor="let option of dateOperatorOptions"
                [value]="option">
                {{ option.label }}
              </mat-option>
            </ng-container>
            <ng-container *ngSwitchCase="'category_multiselect'"
              ><mat-option
                *ngFor="let option of multiselectOperatorOptions"
                [value]="option">
                {{ option.label }}
              </mat-option></ng-container
            >
            <ng-container *ngSwitchCase="'boolean'"
              ><mat-option
                *ngFor="let option of booleanOperatorOptions"
                [value]="option">
                {{ option.label }}
              </mat-option></ng-container
            >
            <ng-container *ngSwitchDefault>
              <mat-option
                *ngFor="let option of operatorOptions"
                [value]="option">
                {{ option.label }}
              </mat-option>
            </ng-container>
          </span>
        </mat-select>
      </mat-form-field>

      <!-- Short Text | URL | HTML -->
      <ng-container
        *ngIf="
          !(
            this.form.operator.value === 'exists' ||
            this.form.operator.value === '!exists'
          ) &&
          [
            attribute_type.short_text,
            attribute_type.url,
            attribute_type.html,
            attribute_type.completeness
          ].indexOf(form.attribute.type) > -1
        ">
        <mat-form-field class="w-full thin-form-control">
          <input
            matInput
            [(ngModel)]="form.value"
            placeholder="Enter Value"
            #input />
        </mat-form-field>
      </ng-container>

      <!-- Decimal -->
      <ng-container
        *ngIf="[attribute_type.decimal].indexOf(form.attribute.type) !== -1">
        <mat-form-field class="w-full thin-form-control">
          <input
            matInput
            type="number"
            class="hide-number-spinner"
            [(ngModel)]="form.value"
            placeholder="Enter Decimal Value"
            #decimalInput />
        </mat-form-field>
      </ng-container>

      <!-- Integer -->
      <ng-container
        *ngIf="[attribute_type.integer].indexOf(form.attribute.type) > -1">
        <mat-form-field
          class="w-full thin-form-control"
          [class.mat-form-field-invalid]="integerInput.hasError"
          [class.no-error-spacing]="integerInput.hasError">
          <input
            matInput
            type="number"
            class="hide-number-spinner"
            (blur)="isIntegerNumber(form.value, integerInput)"
            [(ngModel)]="form.value"
            placeholder="Enter Integer Value"
            #integerInput />
        </mat-form-field>
        <p
          class="text-sm color-error -mt-[5px] mb-[5px]"
          *ngIf="integerInput.hasError">
          Invalid <strong>integer</strong> number
        </p>
      </ng-container>

      <!-- Paragraph -->
      <ng-container
        *ngIf="[attribute_type.paragraph].indexOf(form.attribute.type) > -1">
        <mat-form-field class="w-full thin-form-control h-auto">
          <textarea
            matInput
            placeholder="Enter Value"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            [(ngModel)]="form.value"></textarea>
        </mat-form-field>
      </ng-container>

      <!-- Date  -->
      <ng-container
        *ngIf="[attribute_type.date].indexOf(form.attribute.type) > -1">
        <ng-container *ngIf="form.dateFilterType === 'custom_date'">
          <mat-checkbox
            class="text-[13px] mb-[16px] w-full"
            color="primary"
            [(ngModel)]="form.filterByTime"
            (change)="filterByTimeCheckboxChange(form.filterByTime)"
            #filterByTimeCheckbox
            >Filter by time</mat-checkbox
          >
          <mat-form-field class="w-full thin-form-control">
            <mtx-datetimepicker
              #datetimePicker
              [type]="form.filterByTime ? 'datetime' : 'date'"
              [mode]="'auto'"
              [twelvehour]="true"
              [timeInput]="form.filterByTime"></mtx-datetimepicker>
            <input
              [mtxDatetimepicker]="datetimePicker"
              [(ngModel)]="form.value"
              matInput />
            <mtx-datetimepicker-toggle
              [for]="datetimePicker"
              matSuffix></mtx-datetimepicker-toggle>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="form.dateFilterType === 'relative_date'">
          <mat-form-field
            class="w-full thin-form-control"
            [class.mat-form-field-invalid]="integerDayInput.hasError"
            [class.no-error-spacing]="integerDayInput.hasError">
            <span matPrefix class="text-sm">Last</span>
            <input
              matInput
              type="number"
              class="hide-number-spinner pr-[10px] min-w-0"
              (blur)="isPositiveIntegerNumber(form.value, integerDayInput)"
              [(ngModel)]="form.value"
              placeholder="Enter Day"
              #integerDayInput />
            <span matPostfix class="text-xs whitespace-nowrap"
              >Days <span class="text-slate-400">(UTC time)</span></span
            >
          </mat-form-field>
          <p
            class="text-sm color-error -mt-[5px] mb-[5px]"
            *ngIf="integerDayInput.hasError">
            Enter <strong>Positive and Integer</strong> number
          </p>
        </ng-container>
      </ng-container>

      <!-- Dropdown -->
      <ng-container
        *ngIf="[attribute_type.dropdown].indexOf(form.attribute.type) > -1">
        <mat-form-field class="w-full thin-form-control">
          <mat-select [(ngModel)]="form.value" placeholder="Select Value">
            <mat-option
              *ngFor="let option of form.attribute.settings?.options"
              [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <!-- Boolean -->
      <ng-container
        *ngIf="[attribute_type.boolean].indexOf(form.attribute.type) > -1">
        <mat-form-field class="w-full thin-form-control">
          <mat-select [(ngModel)]="form.value" placeholder="Select Value">
            <mat-option [value]="true">True</mat-option>
            <mat-option [value]="false">False</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <!-- Multi Select -->
      <!-- <ng-container
        *ngIf="
          [attribute_type.multiselect].indexOf(form.attribute.type) > -1 &&
          (form.operator.value === 'like' || form.operator.value === '!like')
        ">
        <mat-form-field class="w-full thin-form-control">
          <mat-select
            [(ngModel)]="form.value"
            placeholder="Select Value"
            multiple>
            <mat-option
              *ngFor="let option of form.attribute.settings?.options"
              [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container> -->
      <ng-container
        *ngIf="
          [attribute_type.category_multiselect].indexOf(form.attribute.type) >
            -1 &&
          (form.operator.value === 'in' || form.operator.value === '!in')
        ">
        <div class="multi-select-btn" (click)="onCategoriesEdit()">
          {{
            form.value?.length
              ? '(' +
                form.value?.length +
                ') ' +
                (form.value?.length ? 'Categories' : 'Category') +
                ' Selected'
              : 'All Categories'
          }}
        </div>
      </ng-container>
    </ng-container>
    <button
      mat-flat-button
      color="primary"
      class="w-full text-sm"
      [disabled]="form.attribute === ''"
      (click)="applyColumnFilter(true)">
      Apply Filter
    </button>
  </div>
</div>
<div *ngIf="isCategories">
  <app-product-category-filter
    (OnSave)="onCategoriesSave($event)"
    (OnClose)="isCategories = false"
    [preSelectedCategories]="form.value"></app-product-category-filter>
</div>

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Attribute_Types } from 'app/core/config/app.config';
import { ProductAttributeGroup } from 'app/modules/settings/product-attributes/product-attributes.types';
import { ProductAttribute } from 'app/shared/intefaces/product.types';
import _ from 'lodash';

@Component({
  selector: 'eco-attribute-selector',
  templateUrl: './attribute-selector.component.html',
  styleUrls: ['./attribute-selector.component.scss'],
})
export class AttributeSelectorComponent implements OnInit, OnDestroy {
  @ViewChild('attributeTree') attributeTree: MatAccordion;

  @Output() selectedAttributesChange: EventEmitter<any> = new EventEmitter();

  selectedAttributesLocalHandling: any = Array();

  @Input('selectedAttributes') set _selectedAttributes(
    value: ProductAttribute[]
  ) {
    this.selectedAttributes = [];
    if (value)
      value.forEach(attribute => {
        if (attribute['integration_id']) {
          let selectedIntegrationIndex = this.selectedAttributes.findIndex(
            sAttribute => {
              return (
                sAttribute['integration_id'] == attribute['integration_id']
              );
            }
          );
          // console.log('111 attribute >>>>>', attribute);
          if (selectedIntegrationIndex > -1) {
            this.selectedAttributes[selectedIntegrationIndex].attributes.push(
              attribute
            );
          } else {
            this.selectedAttributes.push({
              attributes: [attribute],
              code: '',
              created_at: '',
              description: '',
              integration_id: attribute.integration_id,
              integration_name: attribute.integration_name,
              label: '',
              type: '',
            });
          }
        } else {
          // console.log('222 attribute >>>>>', attribute);
          this.selectedAttributes.push(attribute);
        }
      });
    // this.selectedAttributes = value;
    this.selectedAttributesLocalHandling = this.selectedAttributes;
    this.backupSelectedAttribute = JSON.parse(
      JSON.stringify(this.selectedAttributesLocalHandling)
    );
    // console.log(this.selectedAttributesLocalHandling);
    setTimeout(() => {
      this.reArrangeDragableAttributes();
      this.refreshSelectAllCheckbox();
    }, 0);
  }
  @Input('attributeGroups') set _attributeGroups(
    value: ProductAttributeGroup[]
  ) {
    // console.log('Input Change : attributeGroups: ', value);
    this.attributeGroups = value;
    setTimeout(() => {
      this.attributeGroups = this.attributeGroups.map(
        (group: ProductAttributeGroup) => {
          let arr = [];
          group?.attributes?.forEach(item => {
            !this.non_closable_codes.includes(item.code)
              ? arr.push(item)
              : null;
          });
          group.attributes = arr;
          return group;
        }
      );

      if (this.integrationAttributesList?.product_attributes?.length > 0)
        this.attributeGroups.forEach(attribute => {
          if (attribute.name == 'Integration attributes') {
            attribute['integrations'] = [];
            attribute['integrations'] =
              this.integrationAttributesList?.product_attributes || [];
          }
        });
      console.log(this.attributeGroups);
      this.attributeGroups = this.sortAttributes(this.attributeGroups);
      this.backupGroups = JSON.parse(JSON.stringify(this.attributeGroups));
      this.refreshUserSettingSelectedAttributesFromGroup();
      this.refreshSelectAllCheckbox();
    }, 0);
  }

  integrationAttributesList: any = Array();
  @Input('integrationAttributesList') set _integrationAttributesList(
    value: ProductAttribute[]
  ) {
    // console.log('Input Change : _integrationAttributesList: ', value);
    this.integrationAttributesList = value;
    // this.selectedAttributesLocalHandling = value;
    // setTimeout(() => {
    //     this.reArrangeDragableAttributes();
    //     this.refreshSelectAllCheckbox();
    // }, 0);
  }

  @Input() isHide: boolean = false;

  @Input() leftTitle: string = 'Select Attributes';
  @Input() rightTitle: string = 'Selected Attributes';
  @Input() non_dragable_codes: Array<string> = [];
  @Input() non_closable_codes: Array<string> = [];

  attribute_Types: any = Attribute_Types;
  attributeGroups: ProductAttributeGroup[] = [];
  selectedAttributes: ProductAttribute[] = [];
  allPanelExpanded: boolean = false;
  selectedAttrQuery: string = '';
  isFiltered: boolean = false;
  backupGroups: ProductAttributeGroup[];
  backupSelectedAttribute: ProductAttribute[];

  lodash: any = _;
  isSelectedAttributeUpdatedOnce: boolean = false;
  searchText: string;
  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.attributeGroups = this.attributeGroups.map(item => {
      item['allSelected'] = false;
      return item;
    });
  }

  handleDropColumn(event: CdkDragDrop<string[]>) {
    // console.log('handleDropColumn >>>>>>>', event);
    // Here we are splicing some non-dragable elements from top of the array. so we need to shift our drag previous and current index by non-dragable items's length
    moveItemInArray(
      this.selectedAttributesLocalHandling,
      event.previousIndex + this.non_dragable_codes.length,
      event.currentIndex + this.non_dragable_codes.length
    );
    // this.selectedAttributesChange.emit(this.selectedAttributesLocalHandling);
    this.syncSelectedAttributes();
  }

  handleDropIntegrationColumn(event: CdkDragDrop<string[]>, parentIndex) {
    // Here we are splicing some non-dragable elements from top of the array. so we need to shift our drag previous and current index by non-dragable items's length
    moveItemInArray(
      this.selectedAttributesLocalHandling[
        this.non_dragable_codes.length + parentIndex
      ].attributes,
      event.previousIndex,
      event.currentIndex
    );
    // this.selectedAttributesChange.emit(this.selectedAttributesLocalHandling);
    this.syncSelectedAttributes();
  }

  searchAttribute(value) {
    if (value == null || value == '') {
      this.clearSearchAttribute();
      return;
    }
    value = value.toLowerCase();
    this.attributeGroups = JSON.parse(JSON.stringify(this.backupGroups));
    this.attributeGroups = this.attributeGroups.filter(group => {
      if (group?.['integrations'] && group?.['integrations'].length > 0) {
        group['integrations'] = group['integrations'].sort((a: any, b: any) =>
          a.name.localeCompare(b.name, undefined, {
            sensitivity: 'base',
          })
        );
        group['integrations'] = group['integrations'].filter(attribute => {
          let labels = attribute.attributes.map(attr => attr.label);
          labels = labels.filter(label => label.toLowerCase().includes(value));
          labels = labels.sort((a, b) => a - b);
          if (labels.length > 0) {
            return true;
          } else {
            return false;
          }
        });
        if (group['integrations'].length > 0) {
          group['integrations'] = group['integrations'].map(
            (group: ProductAttributeGroup) => {
              let labels = group.attributes.map(attr => attr.label);
              labels = labels.filter(label =>
                label.toLowerCase().includes(value)
              );
              labels = labels.sort((a, b) => a.localeCompare(b));
              let arr = [];
              group?.attributes?.forEach(item => {
                labels.includes(item.label) ? arr.push(item) : null;
              });
              if (arr.length) {
                arr = arr.sort((a: any, b: any) =>
                  a.label.localeCompare(b.label, undefined, {
                    sensitivity: 'base',
                  })
                );
              }

              return group;
            }
          );
        }
        return false;
      } else {
        return true;
      }
    });

    this.attributeGroups = this.attributeGroups.filter(group => {
      if (!(group?.['integrations'] && group?.['integrations'].length > 0)) {
        let labels = group.attributes.map(attr => attr.label);
        labels = labels.filter(label => label.toLowerCase().includes(value));
        labels = labels.sort((a, b) => a.localeCompare(b));
        if (labels.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
    this.attributeGroups = this.attributeGroups.map(
      (group: ProductAttributeGroup) => {
        if (!(group?.['integrations'] && group?.['integrations'].length > 0)) {
          let labels = group.attributes.map(attr => attr.label);
          labels = labels.filter(label => label.toLowerCase().includes(value));
          labels = labels.sort((a, b) => a.localeCompare(b));
          console.log(labels);
          let arr = [];
          group?.attributes?.forEach(item => {
            labels.includes(item.label) ? arr.push(item) : null;
          });
          if (arr.length)
            group.attributes = arr.sort((a: any, b: any) =>
              a.label.localeCompare(b.label, undefined, { sensitivity: 'base' })
            );
          return group;
        } else {
          return group;
        }
      }
    );
    this.attributeGroups = this.attributeGroups.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
    );
    console.log(this.attributeGroups);
    setTimeout(() => {
      this.attributeTree.openAll();
      this.refreshSelectAllCheckbox();
      this.cd.detectChanges();
    }, 100);
  }

  searchSelectedAttribute(value) {
    if (value == null || value == '') {
      this.clearSearchSelectedAttribute();
      return;
    }
    this.selectedAttributesLocalHandling = JSON.parse(
      JSON.stringify(this.backupSelectedAttribute)
    );
    this.selectedAttributesLocalHandling =
      this.selectedAttributesLocalHandling.filter(attribute => {
        if (!attribute.code) {
          let labels = attribute.attributes.map(attr => attr.label);
          labels = labels.filter(label => label.toLowerCase().includes(value));
          let arr = [];
          attribute.attributes.forEach(item => {
            labels.includes(item.label) ? arr.push(item) : null;
          });
          attribute.attributes = this.sortArray(arr, 'label');
          return attribute;
        } else {
          if (attribute.label.toLowerCase().includes(value)) return attribute;
          return false;
        }
      });
  }

  clearSearchAttribute() {
    this.isFiltered = false;
    this.attributeGroups = JSON.parse(JSON.stringify(this.backupGroups));
    this.cd.detectChanges();
  }

  clearSearchSelectedAttribute() {
    this.selectedAttributesLocalHandling = JSON.parse(
      JSON.stringify(this.backupSelectedAttribute)
    );
    this.cd.detectChanges();
  }

  selectAllAttribute(isChecked, index) {
    this.attributeGroups[index]['allSelected'] = isChecked;
    if (this.attributeGroups[index]?.attributes == null) {
      return;
    }
    if (isChecked) {
      const map = _.chain(this.selectedAttributesLocalHandling)
        .keyBy('code')
        .value();
      (this.attributeGroups[index]?.attributes).forEach(item => {
        if (!(item.code in map)) {
          this.selectedAttributesLocalHandling.push(item);
        }
      });
    } else {
      this.selectedAttributesLocalHandling =
        this.selectedAttributesLocalHandling.filter(
          attr => !this.attributeGroups[index]?.attr_map[attr.code]
        );
    }
    this.refreshSelectAllCheckbox();
    // this.selectedAttributesChange.emit(this.selectedAttributesLocalHandling);
    // this.cd.detectChanges();
    this.syncSelectedAttributes();
  }

  isIntermediateSelected(index) {
    if (this.attributeGroups[index]?.attributes == null) {
      return false;
    }
    return (
      this.selectedAttributesLocalHandling.filter(
        attr => this.attributeGroups[index]?.attr_map[attr.code]
      ).length > 0 && !this.attributeGroups[index]['allSelected']
    );
  }

  isIntermediateIntegrationSelected(index, intGroup) {
    let flag = false;
    if (intGroup) {
      let attrObj = this.selectedAttributesLocalHandling.findIndex(attr => {
        return attr.integration_id == intGroup.id;
      });
      if (attrObj > -1) {
        if (
          this.selectedAttributesLocalHandling[attrObj].attributes.length ==
          intGroup?.attributes.length
        ) {
          flag = false;
        } else {
          flag = true;
        }
      }
    } else {
      let groupIndex = this.attributeGroups.findIndex(group => {
        return group.name == 'Integration attributes';
      });
      let count = 0;
      let attributeCount = 0;
      if (
        groupIndex > -1 &&
        this.attributeGroups[groupIndex] &&
        this.attributeGroups[groupIndex]['integrations']
      ) {
        this.attributeGroups[groupIndex]['integrations'].forEach(
          (element, i) => {
            let intIndex = this.selectedAttributesLocalHandling.findIndex(
              int => {
                return int.integration_id == element.id;
              }
            );
            if (intIndex > -1) {
              count +=
                this.selectedAttributesLocalHandling[intIndex].attributes
                  .length;
              attributeCount += element.attributes.length;
            } else {
              attributeCount += element.attributes.length;
            }
          }
        );
        if (attributeCount == count) {
          flag = false;
        } else {
          if (count > 0) flag = true;
        }
      }
    }
    return flag;
  }

  selectAllIntegrationAttribute(isChecked, index) {
    if (index == -1) {
      let groupIndex = this.attributeGroups.findIndex(group => {
        return group.name == 'Integration attributes';
      });
      this.attributeGroups[groupIndex]['allSelected'] = isChecked;
      if (isChecked) {
        this.attributeGroups[groupIndex]['integrations'].forEach(
          (element, i) => {
            let intIndex = this.selectedAttributesLocalHandling.findIndex(
              int => {
                return int.integration_id == element.id;
              }
            );
            element.allSelected = isChecked;
            if (intIndex > -1) {
              const map = _.chain(
                this.selectedAttributesLocalHandling[intIndex].attributes
              )
                .keyBy('code')
                .value();
              this.attributeGroups[groupIndex]['integrations'][
                i
              ].attributes.forEach(item => {
                if (!(item.code in map)) {
                  this.selectedAttributesLocalHandling[
                    intIndex
                  ].attributes.push(item);
                }
              });
            } else {
              this.selectedAttributesLocalHandling.push({
                attributes: element.attributes,
                code: '',
                created_at: '',
                description: '',
                integration_id: element.id,
                integration_name: element.name,
                label: '',
                type: '',
              });
            }
          }
        );
      } else {
        this.attributeGroups[groupIndex]['integrations'].forEach(
          (element, i) => {
            let intIndex = this.selectedAttributesLocalHandling.findIndex(
              int => {
                return int.integration_id == element.id;
              }
            );
            element.allSelected = isChecked;
            if (intIndex > -1) {
              this.selectedAttributesLocalHandling.splice(intIndex, 1);
            }
          }
        );
      }
    } else {
      let groupIndex = this.attributeGroups.findIndex(group => {
        return group.name == 'Integration attributes';
      });
      if (isChecked) {
        let element = this.attributeGroups[groupIndex]['integrations'][index];
        let intIndex = this.selectedAttributesLocalHandling.findIndex(int => {
          return int.integration_id == element.id;
        });
        element.allSelected = isChecked;
        if (intIndex > -1) {
          const map = _.chain(
            this.selectedAttributesLocalHandling[intIndex].attributes
          )
            .keyBy('code')
            .value();
          element.attributes.forEach(item => {
            if (!(item.code in map)) {
              this.selectedAttributesLocalHandling[intIndex].attributes.push(
                item
              );
            }
          });
        } else {
          this.selectedAttributesLocalHandling.push({
            attributes: element.attributes,
            code: '',
            created_at: '',
            description: '',
            integration_id: element.id,
            integration_name: element.name,
            label: '',
            type: '',
          });
        }
      } else {
        let element = this.attributeGroups[groupIndex]['integrations'][index];
        let intIndex = this.selectedAttributesLocalHandling.findIndex(int => {
          return int.integration_id == element.id;
        });
        element.allSelected = isChecked;
        if (intIndex > -1) {
          this.selectedAttributesLocalHandling.splice(intIndex, 1);
        }
      }
    }

    this.refreshSelectAllCheckbox();
    this.syncSelectedAttributes();
  }

  onSelectAttribute(attribute, value) {
    if (value == false) {
      this.selectedAttributesLocalHandling =
        this.selectedAttributesLocalHandling.filter(
          attr => attr?.code != attribute.code
        );
    } else {
      this.selectedAttributesLocalHandling.push(attribute);
    }
    this.refreshSelectAllCheckbox();
    // this.selectedAttributesChange.emit(this.selectedAttributesLocalHandling);
    this.syncSelectedAttributes();
  }

  onSelectIntegrationAttribute(attribute, value, integration, childIndex) {
    let selectedIntegrationIndex =
      this.selectedAttributesLocalHandling.findIndex(sAttribute => {
        return sAttribute['integration_id'] == integration.id;
      });
    if (value == false) {
      this.selectedAttributesLocalHandling[
        selectedIntegrationIndex
      ].attributes.splice(childIndex, 1);
      // this.selectedAttributesLocalHandling = this.selectedAttributesLocalHandling.filter((attr) => attr?.code != attribute.code);
    } else {
      if (selectedIntegrationIndex > -1) {
        this.selectedAttributesLocalHandling[
          selectedIntegrationIndex
        ].attributes.push(attribute);
      } else {
        this.selectedAttributesLocalHandling.push({
          label: '',
          code: '',
          description: '',
          type: '',
          created_at: '',
          integration_id: integration.id,
          integration_name: integration.name,
          attributes: [attribute],
        });
      }
    }
    this.refreshSelectAllCheckbox();
    // this.selectedAttributesChange.emit(this.selectedAttributesLocalHandling);
    this.syncSelectedAttributes();
  }

  refreshSelectAllCheckbox() {
    this.attributeGroups.map(group => {
      if (group['name'] != 'Integration attributes')
        group['allSelected'] =
          group?.attributes != null &&
          this.selectedAttributesLocalHandling.filter(
            attr => group?.attr_map[attr.code]
          ).length == group?.attributes.length;
    });
    // console.log('OUT refreshSelectAllCheckbox this.attributeGroups >>>>>', this.attributeGroups);

    this.attributeGroups.forEach((group, i) => {
      if (group['name'] == 'Integration attributes') {
        let selectedIntegrationIndex = -1;
        // console.log('group >>>>>>>>>>', group);
        if (group['integrations'])
          group['integrations'].forEach(intAttr => {
            selectedIntegrationIndex = -1;
            selectedIntegrationIndex =
              this.selectedAttributesLocalHandling.findIndex(sAttribute => {
                return sAttribute['integration_id'] == intAttr['id'];
              });
            if (selectedIntegrationIndex > -1)
              if (
                intAttr?.attributes.length ==
                this.selectedAttributesLocalHandling[selectedIntegrationIndex]
                  .attributes.length
              ) {
                intAttr.allSelected = true;
              } else {
                intAttr.allSelected = false;
              }
          });
      }
    });
    // }
  }

  unselectAttribute(attribute) {
    this.selectedAttributesLocalHandling =
      this.selectedAttributesLocalHandling.filter(
        attr => attr.code !== attribute.code
      );
    this.refreshSelectAllCheckbox();
    // this.selectedAttributesChange.emit(this.selectedAttributesLocalHandling);
    // this.cd.detectChanges();
    this.syncSelectedAttributes();
  }

  unselectIntegrationAttribute(parentIndex, childIndex) {
    if (childIndex == -1) {
      this.selectedAttributesLocalHandling.splice(
        this.non_dragable_codes.length + parentIndex,
        1
      );
    } else {
      this.selectedAttributesLocalHandling[
        this.non_dragable_codes.length + parentIndex
      ].attributes.splice(childIndex, 1);
    }
    this.refreshSelectAllCheckbox();
    this.syncSelectedAttributes();
    // this.selectedAttributesChange.emit(this.selectedAttributesLocalHandling);
    // this.cd.detectChanges();
  }

  clearAllSelectedLabels() {
    this.selectedAttributesLocalHandling = [];
    this.refreshSelectAllCheckbox();
    // this.selectedAttributesChange.emit(this.selectedAttributesLocalHandling);
    // this.cd.detectChanges();
    this.syncSelectedAttributes();
  }

  collapseAllGroups() {
    this.attributeTree.closeAll();
  }

  refreshUserSettingSelectedAttributesFromGroup() {
    if (this.attributeGroups && this.attributeGroups?.length > 0) {
      let attributeMap = {};
      this.attributeGroups.forEach((group: ProductAttributeGroup) => {
        group?.attributes?.forEach(attribute => {
          attribute?.code ? (attributeMap[attribute?.code] = attribute) : null;
        });
      });
      this.selectedAttributesLocalHandling =
        this.selectedAttributesLocalHandling.map((item: any) => {
          if (
            item?.code &&
            attributeMap[item?.code] &&
            attributeMap[item?.code]?.label != item?.label
          ) {
            // console.log(
            //   'replaced attribute : ',
            //   item?.label,
            //   'to ',
            //   attributeMap[item?.code]['label']
            // );
            delete attributeMap[item?.code]['id'];
            return attributeMap[item?.code];
          } else {
            return item;
          }
        });
      // this.selectedAttributesChange.emit(this.selectedAttributesLocalHandling);
      this.syncSelectedAttributes();
    }
  }

  reArrangeDragableAttributes() {
    const non_dragable = [];
    this.non_dragable_codes = this.non_dragable_codes.map(item => {
      let index = this.selectedAttributesLocalHandling.findIndex(
        attr => attr.code == item
      );
      if (index > -1) {
        let [spliced] = this.selectedAttributesLocalHandling.splice(index, 1);
        non_dragable.push(spliced);
        this.selectedAttributesLocalHandling.unshift(spliced);
        return item;
      } else {
        return null;
      }
    });
  }

  syncSelectedAttributes() {
    this.selectedAttributes = [];
    this.selectedAttributesLocalHandling.forEach(attribute => {
      if (attribute.integration_id) {
        attribute.attributes.forEach(intAttribute => {
          intAttribute.integration_id = attribute.integration_id;
          intAttribute.integration_name = attribute.integration_name;
          this.selectedAttributes.push(intAttribute);
        });
      } else {
        this.selectedAttributes.push(attribute);
      }
    });
    this.selectedAttributesChange.emit(this.selectedAttributes);
    this.cd.detectChanges();
  }

  getListing(intGroup) {
    let attrObj = this.selectedAttributesLocalHandling.find(attr => {
      return attr.integration_id == intGroup.id;
    });
    if (attrObj?.['integration_id']) return attrObj.attributes;
    else return [];
  }

  private sortArray(array: any[], key = null): any[] {
    return array.sort((a, b) => {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    });
  }

  private sortAttributes(attibutesArr) {
    attibutesArr.forEach(group => {
      if (group?.['integrations'] && group?.['integrations'].length > 0) {
        group['integrations'] = group['integrations'].sort((a: any, b: any) =>
          a.name.localeCompare(b.name, undefined, {
            sensitivity: 'base',
          })
        );
        group['integrations'] = group['integrations'].filter(attribute => {
          let labels = attribute.attributes.map(attr => attr.label);
          labels = labels.sort((a, b) => a - b);
          if (labels.length > 0) {
            return true;
          } else {
            return false;
          }
        });
        if (group['integrations'].length > 0) {
          group['integrations'] = group['integrations'].map(
            (group: ProductAttributeGroup) => {
              let labels = group.attributes.map(attr => attr.label);
              labels = labels.sort((a, b) => a.localeCompare(b));
              let arr = [];
              group?.attributes?.forEach(item => {
                labels.includes(item.label) ? arr.push(item) : null;
              });
              if (group?.attributes) {
                group.attributes = group.attributes.sort((a: any, b: any) =>
                  a.label.localeCompare(b.label, undefined, {
                    sensitivity: 'base',
                  })
                );
              }

              return group;
            }
          );
        }
        return false;
      } else {
        return true;
      }
    });
    attibutesArr.forEach(group => {
      if (!(group?.['integrations'] && group?.['integrations'].length > 0)) {
        let labels = group.attributes.map(attr => attr.label);
        labels = labels.sort((a, b) => a.localeCompare(b));
      }
    });
    attibutesArr.forEach((group: ProductAttributeGroup) => {
      if (!(group?.['integrations'] && group?.['integrations'].length > 0)) {
        let labels = group.attributes.map(attr => attr.label);
        labels = labels.sort((a, b) => a.localeCompare(b));
        console.log(labels);
        if (group?.attributes)
          group.attributes = group?.attributes?.sort((a: any, b: any) =>
            a.label.localeCompare(b.label, undefined, { sensitivity: 'base' })
          );
        return group;
      } else {
        return group;
      }
    });
    attibutesArr = attibutesArr.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
    );
    console.log(attibutesArr);
    return attibutesArr;
  }
}

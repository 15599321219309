import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ProductService } from '@services';

@Component({
  selector: 'app-product-category-filter',
  templateUrl: './product-category-filter.component.html',
  styleUrls: ['./product-category-filter.component.scss'],
  //   encapsulation: ViewEncapsulation.None,
  //   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCategoryFilterComponent implements OnInit {
  @Output() OnSave: EventEmitter<any> = new EventEmitter();
  @Output() OnClose: EventEmitter<null> = new EventEmitter();
  @Input() selectedCategories: any[] = [];
  @Input() preSelectedCategories: any[] = [];
  isLoading: boolean = true;
  selectedCategory: any = {};
  selectedParentNode: any = {};
  categories: any[] = [];
  parentId: number = 0;
  searchText: string = '';
  recursiveOperations = {
    DESELECT_ALL: 'DESELECT_ALL',
    COLLAPSE_ALL: 'COLLAPSE_ALL',
    DESELECT_SINGLE: 'DESELECT_SINGLE',
  };

  constructor(private productService: ProductService) {}

  ngOnInit(): void {
    if (this.preSelectedCategories?.length) {
      //   let SelectedCodesList: string[] = [];
      //   SelectedCodesList = this.preSelectedCategories.map(elem => {
      //     return elem;
      //   });
      this.getSelectedCategoryCodes();
    } else {
      this.getCategories();
    }
    console.log(this.selectedCategories);
  }

  onLoadChildren(val: any) {
    this.selectedParentNode = val;
    this.getCategories(val.code);
  }

  clearAllSelected() {
    this.selectedCategories = [];
    this.recursiveCategorySearch(
      this.categories,
      this.recursiveOperations.DESELECT_ALL
    );
  }

  onCollapseAll() {
    this.recursiveCategorySearch(
      this.categories,
      this.recursiveOperations.COLLAPSE_ALL
    );
  }

  onCategorySelect(val) {
    if (val.isSelected) {
      const Index = this.selectedCategories.findIndex(
        elem => elem.code === val.code
      );
      if (Index < 0) this.selectedCategories.push(val);
    } else {
      const Index = this.selectedCategories.findIndex(
        elem => elem.code === val.code
      );
      if (Index > -1) this.selectedCategories.splice(Index, 1);
    }
  }

  removeSelectedCategory(val) {
    this.selectedCategory = val;
    const Index = this.selectedCategories.findIndex(
      categoryElem => categoryElem.code === this.selectedCategory.code
    );
    if (Index > -1) this.selectedCategories.splice(Index, 1);
    this.recursiveCategorySearch(
      this.categories,
      this.recursiveOperations.DESELECT_SINGLE
    );
  }

  searchCategory(val: string) {
    this.searchText = val;
    const ParentId = 0;
    this.getCategories(ParentId);
  }

  onSave() {
    this.OnSave.emit(this.selectedCategories);
  }

  onClose() {
    this.OnClose.emit();
  }

  private recursiveCategorySearch(categories, operation) {
    categories.forEach(elem => {
      switch (operation) {
        case this.recursiveOperations.COLLAPSE_ALL:
          elem['showChildren'] = false;
          if (elem.children?.length) {
            this.recursiveCategorySearch(
              elem.children,
              this.recursiveOperations.COLLAPSE_ALL
            );
          }
          break;

        case this.recursiveOperations.DESELECT_ALL:
          elem['isSelected'] = false;
          if (elem.children?.length) {
            this.recursiveCategorySearch(
              elem.children,
              this.recursiveOperations.DESELECT_ALL
            );
          }
          break;

        case this.recursiveOperations.DESELECT_SINGLE:
          if (elem.code === this.selectedCategory.code) {
            elem.isSelected = false;
          } else {
            if (elem.children?.length) {
              this.recursiveCategorySearch(
                elem.children,
                this.recursiveOperations.DESELECT_SINGLE
              );
            }
          }
          break;
      }
    });
  }

  /**
   * API's
   */

  private getCategories(parentId = 0) {
    const object = {
      search: this.searchText || '',
      parent: parentId,
      size: 1000,
    };
    this.productService.getCategories(object).subscribe(
      (response: any) => {
        this.categories = response.category.map(elem => {
          // !! Write the same login in app-product-category-tree-node
          const Index = this.selectedCategories?.findIndex(
            categoryElem => categoryElem.code === elem.code
          );
          if (Index > -1) elem.isSelected = true;
          else elem.isSelected = false;
          return elem;
        });
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
      }
    );
  }

  private getSelectedCategoryCodes() {
    const object = {
      code: this.preSelectedCategories,
    };
    this.productService.getCategories(object).subscribe(
      (response: any) => {
        this.selectedCategories = response.category;
        this.getCategories();
      },
      error => {}
    );
  }
}

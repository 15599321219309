import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appConfig } from 'app/core/config/app.config';
import { Observable } from 'rxjs';
import { SnackbarService } from 'app/services/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private _config = appConfig;
  constructor(
    private http: HttpClient,
    private _snackbarService: SnackbarService
  ) {}

  register(data: any) {
    const api = this._config?.apiConfig?.baseUrl;
    return new Observable(observable => {
      this.http.post(`${api}/register`, data, {}).subscribe({
        next: (res: any) => {
          console.log(res);
          if (res.success) {
            this._snackbarService.showSuccess(res.message);
            observable.next(res.result);
          } else {
            observable.error(res);
            // this._snackbarService.showError(res.errors.message);
          }
        },
      }),
        (err: any) => {
          console.log(err);
          this._snackbarService.showError(err.error.message);
          observable.error(err);
        };
    });
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}
  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    token && localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  /**
   * Setter & getter for token expiration date
   */
  set tokenExpirationDate(expirationDate: number) {
    expirationDate &&
      localStorage.setItem('tokenExpirationDate', expirationDate.toString());
  }

  get tokenExpirationDate(): number {
    return +localStorage.getItem('tokenExpirationDate') ?? 0;
  }

  /**
   * Setter and getter for whole login details
   */
  set ecommify_user(val: any) {
    val && localStorage.setItem('ecommify_user', JSON.stringify(val));
  }

  get ecommify_user() {
    const UserVal = localStorage.getItem('ecommify_user');
    if (UserVal) {
      const User = JSON.parse(UserVal);
      return User;
    } else return null;
  }

  /**
   * Getter for logged in user timezone
   */
  get userTimezone() {
    return this.getUserTimezone();
  }

  // setter & getter for role
  set role(role: string) {
    role && localStorage.setItem('role', role);
  }

  get role(): string {
    return localStorage.getItem('role') ?? '';
  }

  deleteLocalStorage() {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');
    //Remove the role from the local storage
    localStorage.removeItem('role');
    //Remove the company Id from local storage
    localStorage.removeItem('companyId');
    //Remove the company name from local storage
    localStorage.removeItem('companyName');
    //Remove the impersonate from local storage
    localStorage.removeItem('impersonate');
    // Set the authenticated flag to false
  }

  private getUserTimezone() {
    const User = this.ecommify_user;
    if (User) {
      return User.timezone;
    } else {
      return null;
    }
  }
}

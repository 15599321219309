import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EcommifyApiResponse } from 'app/core/api/api.types';
import { appConfig } from 'app/core/config/app.config';
import { User } from 'app/core/user/user.types';
import { Observable } from 'rxjs';
import { ApiQueryParamsService } from './misc/api-query-params.service';
import { LocalStorageUtils } from 'app/core/common/local-storage.utils';
import { LocalStorageService } from './localStorage.service';
import { SnackbarService } from 'app/services/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private _config = appConfig;
  constructor(
    private http: HttpClient,
    private apiQueryParamsService: ApiQueryParamsService,
    private localStorageService: LocalStorageService,
    private _snackbarService: SnackbarService
  ) {}

  changeUserPassword(userId, data) {
    const api = this._config?.apiConfig?.baseUrl;
    return new Observable(observable => {
      this.http.put(`${api}/admin/change-password/${userId}`, data).subscribe({
        next: (res: any) => {
          if (res.success) {
            this._snackbarService.showSuccess(res.message);
            observable.next(res.result);
          } else {
            observable.error(res);
            this._snackbarService.showError(res.message);
          }
        },
      }),
        (err: any) => {
          this._snackbarService.showError(err.error.message);
          observable.error(err);
        };
    });
  }

  getAlerts(paramsObj) {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;
    return new Observable(observable => {
      this.http
        .get(
          `${api}/${this.getUrlPathString()}/alerts${this.apiQueryParamsService.get(
            paramsObj
          )}`
        )
        .subscribe({
          next: (res: any) => {
            if (res.success) {
              console.log(res);
              // this.toastr.success({ detail: "Success", summary: res.message });
              observable.next(res.result);
            } else {
              observable.error(res);
              // this.toastr.error({ detail: "Error", summary: res.message });
              this._snackbarService.showError(res.message);
            }
          },
        }),
        (err: any) => {
          this._snackbarService.showError(err.error.message);
          observable.error(err);
        };
    });
  }

  private getUrlPathString() {
    let UrlPathString: string = 'admin';
    const companyID = LocalStorageUtils.companyId;
    const role = this.localStorageService.role;
    if (role === 'admin' || role === 'superAdmin') {
      UrlPathString = 'admin';
    } else {
      UrlPathString = companyID;
    }
    console.log(UrlPathString);
    return UrlPathString;
  }
}

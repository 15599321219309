import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { Message } from 'app/layout/common/messages/messages.types';
import { ApiQueryParamsService } from 'app/services/misc/api-query-params.service';
import { appConfig } from 'app/core/config/app.config';
import { LocalStorageUtils } from 'app/core/common/local-storage.utils';
import { SnackbarService } from 'app/services/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  private _config = appConfig;
  private _messages: ReplaySubject<Message[]> = new ReplaySubject<Message[]>(1);

  /**
   * Constructor
   */
  constructor(
    private http: HttpClient,
    private apiQueryParamsService: ApiQueryParamsService,
    private _snackbarService: SnackbarService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for messages
   */
  get messages$(): Observable<Message[]> {
    return this._messages.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getAlerts(paramsObj) {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId || 'admin';
    return new Observable(observable => {
      this.http
        .get(
          `${api}/${companyID}/alerts${this.apiQueryParamsService.get(
            paramsObj
          )}`
        )
        .subscribe({
          next: (res: any) => {
            if (res.success) {
              //   console.log(res);
              // this.toastr.success({ detail: "Success", summary: res.message });
              observable.next(res.result);
            } else {
              observable.error(res);
              // this.toastr.error({ detail: "Error", summary: res.message });
              this._snackbarService.showError(res.message);
              //   this.snackbar.error(res.message, res.result, {
              //     timeOut: 3000,
              //   });
            }
          },
        }),
        (err: any) => {
          this._snackbarService.showError(err.error.message);
          observable.error(err);
        };
    });
  }

  /**
   * Get all messages
   */
  getAll(): Observable<Message[]> {
    return this.http.get<Message[]>('api/common/messages').pipe(
      tap(messages => {
        this._messages.next(messages);
      })
    );
  }

  /**
   * Create a message
   *
   * @param message
   */
  create(message: Message): Observable<Message> {
    return this.messages$.pipe(
      take(1),
      switchMap(messages =>
        this.http.post<Message>('api/common/messages', { message }).pipe(
          map(newMessage => {
            // Update the messages with the new message
            this._messages.next([...messages, newMessage]);

            // Return the new message from observable
            return newMessage;
          })
        )
      )
    );
  }

  /**
   * Update the message
   *
   * @param id
   * @param message
   */
  update(id: string, message: Message): Observable<Message> {
    return this.messages$.pipe(
      take(1),
      switchMap(messages =>
        this.http
          .patch<Message>('api/common/messages', {
            id,
            message,
          })
          .pipe(
            map((updatedMessage: Message) => {
              // Find the index of the updated message
              const index = messages.findIndex(item => item.id === id);

              // Update the message
              messages[index] = updatedMessage;

              // Update the messages
              this._messages.next(messages);

              // Return the updated message
              return updatedMessage;
            })
          )
      )
    );
  }

  /**
   * Delete the message
   *
   * @param id
   */
  delete(id: string): Observable<boolean> {
    return this.messages$.pipe(
      take(1),
      switchMap(messages =>
        this.http
          .delete<boolean>('api/common/messages', { params: { id } })
          .pipe(
            map((isDeleted: boolean) => {
              // Find the index of the deleted message
              const index = messages.findIndex(item => item.id === id);

              // Delete the message
              messages.splice(index, 1);

              // Update the messages
              this._messages.next(messages);

              // Return the deleted status
              return isDeleted;
            })
          )
      )
    );
  }

  /**
   * Mark all messages as read
   */
  markAllAsRead(): Observable<boolean> {
    return this.messages$.pipe(
      take(1),
      switchMap(messages =>
        this.http.get<boolean>('api/common/messages/mark-all-as-read').pipe(
          map((isUpdated: boolean) => {
            // Go through all messages and set them as read
            messages.forEach((message, index) => {
              messages[index].read = true;
            });

            // Update the messages
            this._messages.next(messages);

            // Return the updated status
            return isUpdated;
          })
        )
      )
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';
import { Pagination } from 'app/layout/common/grid/grid.types';
import { appConfig } from 'app/core/config/app.config';
import { EcommifyApiResponse } from 'app/core/api/api.types';

import { LocalStorageUtils } from 'app/core/common/local-storage.utils';
import { GridUtils } from '../layout/common/grid/grid.utils';
import { ProductPromotionResponse } from '../modules/product-promotion/product-promotion.types';
import { SnackbarService } from 'app/services/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ProductPromotionService {
  // Private
  private _config = appConfig;
  private _productPromotionData: BehaviorSubject<
    ProductPromotionResponse[] | null
  > = new BehaviorSubject(null);
  private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(
    null
  );

  constructor(
    private _httpClient: HttpClient,
    private _snackbarService: SnackbarService
  ) {}

  // Getter for Product Promotion Data Dashboard
  get ProductPromotionData$(): Observable<ProductPromotionResponse[]> {
    return this._productPromotionData.asObservable();
  }

  // Getter for pagination
  get pagination$(): Observable<Pagination> {
    return this._pagination.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Get IntegrationStatus Dashboard Data
   * @param page , @param size, @param sort, @param order, @param search // TBD
   */
  getProductPromotionData(
    page: number = 0,
    size: number = 50,
    search: string = '',
    filterObject = {}
  ): Observable<EcommifyApiResponse<ProductPromotionResponse[]>> {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;
    return this._httpClient
      .post<EcommifyApiResponse<ProductPromotionResponse[]>>(
        `${api}/${companyID}/promotion/search`,
        {
          page: '' + page,
          size: '' + size,
          search,
          ...filterObject,
        }
      )
      .pipe(
        tap((response: any) => {
          const { result } = response;
          const pagination = GridUtils.getPagination(result);
          this._pagination.next(pagination);
          this._productPromotionData.next(result?.integration_instances);
        }),
        catchError((_error: any) => {
          return of(null);
        })
      );
  }

  saveProductPromotionData(body, attributeId: any = null) {
    const api = this._config?.apiConfig?.serviceUrlv1;
    const companyID = LocalStorageUtils.companyId;
    const URL = attributeId
      ? `${api}/${companyID}/promotion/${attributeId}`
      : `${api}/${companyID}/promotion`;
    const Method = attributeId ? 'put' : 'post';

    return this._httpClient[Method]<EcommifyApiResponse<any>>(URL, body).pipe(
      catchError(error => {
        console.log('settings/promotion: create/Update promotion error', error);
        return of(error);
      })
    );
  }

  savePriorityData(body) {
    const api = this._config?.apiConfig?.serviceUrlv1;
    const companyID = LocalStorageUtils.companyId;
    const URL = `${api}/${companyID}/promotion/bulk`;

    return this._httpClient.put<EcommifyApiResponse<any>>(URL, body).pipe(
      catchError(error => {
        console.log('settings/promotion: savePriorityData error', error);
        return of(error);
      })
    );
  }

  getSourceData(
    page: number = 0,
    size: number = 1000,
    sort: string = 'name',
    order: 'asc' | 'desc' | '' = 'asc',
    search: string = ''
  ): Observable<EcommifyApiResponse<any>> {
    const api = this._config?.apiConfig?.baseUrl;
    const companyID = LocalStorageUtils.companyId;

    return this._httpClient
      .get<EcommifyApiResponse<any>>(`${api}/${companyID}/integrations`, {
        params: {
          page: '' + page,
          size: '' + size,
          sort,
          order,
          search,
        },
      })
      .pipe(
        tap((response: any) => {}),
        catchError((_error: any) => {
          return of(null);
        })
      );
  }

  getGroup(
    page: number = 0,
    size: number = 1000,
    sort: string = 'name',
    order: 'asc' | 'desc' | '' = 'asc',
    search: string = ''
  ): Observable<EcommifyApiResponse<any>> {
    const api = this._config?.apiConfig?.serviceUrlv1;
    const companyID = LocalStorageUtils.companyId;

    return this._httpClient
      .post<EcommifyApiResponse<any>>(`${api}/${companyID}/group/search`, {
        page: '' + page,
        size: '' + size,
        sort,
        order,
        search,
      })
      .pipe(
        tap((response: any) => {}),
        catchError((_error: any) => {
          return of(null);
        })
      );
  }

  deleteBulkPromotions(data) {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;
    return new Observable(observable => {
      this._httpClient
        .put(`${api}/${companyID}/promotion/bulk-delete`, data)
        .subscribe({
          next: (res: any) => {
            if (res.status) {
              this._snackbarService.showSuccess(res.message);
              // this.toastr.success({ detail: "Success", summary: res.message });
              observable.next(res.result);
            } else {
              observable.error(res);
              this._snackbarService.showError(res.message);
              // this.toastr.error({ detail: "Error", summary: res.message });
            }
          },
        }),
        (err: any) => {
          this._snackbarService.showError(err.error.message);
          observable.error(err);
        };
    });
  }

  delete(id: string) {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;
    return new Observable(observable => {
      this._httpClient.delete(`${api}/${companyID}/promotion/${id}`).subscribe({
        next: (res: any) => {
          if (res.status) {
            this._snackbarService.showSuccess(res.message);
            // this.toastr.success({ detail: "Success", summary: res.message });
            observable.next(res);
          } else {
            observable.error(res);
            this._snackbarService.showError(res.message);
            // this.toastr.error({ detail: "Error", summary: res.message });
          }
        },
      }),
        (err: any) => {
          this._snackbarService.showError(err.error.message);
          observable.error(err);
        };
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';
import { Pagination } from 'app/layout/common/grid/grid.types';
import { appConfig } from 'app/core/config/app.config';
import { EcommifyApiResponse } from 'app/core/api/api.types';

import { LocalStorageUtils } from 'app/core/common/local-storage.utils';
import { GridUtils } from '../layout/common/grid/grid.utils';
import { SnackbarService } from 'app/services/snackbar.service';
// import { ProductPromotionResponse } from './product-image-resizer.types';

@Injectable({
  providedIn: 'root',
})
export class ProductImageResizerService {
  // Private
  private _config = appConfig;
  private _productPromotionData: BehaviorSubject<any | null> =
    new BehaviorSubject(null);

  private _productImageResizerData: BehaviorSubject<any | null> =
    new BehaviorSubject(null);
  private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(
    null
  );

  constructor(
    private _httpClient: HttpClient,
    private _snackbarService: SnackbarService
  ) {}

  // Getter for Product Promotion Data Dashboard
  get ProductPromotionData$(): Observable<any> {
    return this._productPromotionData.asObservable();
  }

  // Getter for Product Image Resizer Data Dashboard
  get ProductImageResizerData$(): Observable<any> {
    return this._productImageResizerData.asObservable();
  }

  // Getter for pagination
  get pagination$(): Observable<Pagination> {
    return this._pagination.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Get IntegrationStatus Dashboard Data
   * @param page , @param size, @param sort, @param order, @param search // TBD
   */
  getProductPromotionData(
    page: number = 0,
    size: number = 50,
    search: string = ''
  ): Observable<EcommifyApiResponse<any>> {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;

    return this._httpClient
      .post<EcommifyApiResponse<any>>(`${api}/${companyID}/promotion/search`, {
        page: '' + page,
        size: '' + size,
        search,
      })
      .pipe(
        tap((response: any) => {
          const { result } = response;
          this._productPromotionData.next(result?.integration_instances);
        }),
        catchError((_error: any) => {
          return of(null);
        })
      );
  }

  /**
   * Get IntegrationStatus Dashboard Data
   * @param page , @param size, @param sort, @param order, @param search // TBD
   */
  getProductImageResizerData(
    page: number = 0,
    size: number = 50,
    search: string = ''
  ): Observable<EcommifyApiResponse<any>> {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;

    return this._httpClient
      .get<EcommifyApiResponse<any>>(`${api}/${companyID}/image-resizer`, {
        params: {
          page: '' + page,
          size: '' + size,
          search,
        },
      })
      .pipe(
        tap((response: any) => {
          const { result } = response;

          const pagination = GridUtils.getPagination(result);
          this._pagination.next(pagination);
          this._productImageResizerData.next(result?.image_resizer);
        }),
        catchError((_error: any) => {
          return of(null);
        })
      );
  }

  saveProductImageResizerData(body, attributeId: any = null) {
    const api = this._config?.apiConfig?.serviceUrlv1;
    const companyID = LocalStorageUtils.companyId;
    const URL = attributeId
      ? `${api}/${companyID}/image-resizer/${attributeId}`
      : `${api}/${companyID}/image-resizer`;
    const Method = attributeId ? 'put' : 'post';

    return this._httpClient[Method]<EcommifyApiResponse<any>>(URL, body).pipe(
      catchError(error => {
        console.log(
          'settings/ImageResizer: create/Update ImageResizer error',
          error
        );
        return of(error);
      })
    );
  }

  runResizer(id) {
    const api = this._config?.apiConfig?.serviceUrlv1;
    const companyID = LocalStorageUtils.companyId;

    return this._httpClient
      .put<EcommifyApiResponse<any>>(
        `${api}/${companyID}/image-resizer/${id}/run`,
        {}
      )
      .pipe(
        catchError(error => {
          console.log(
            'settings/ImageResizer: create/Update ImageResizer error',
            error
          );
          return of(error);
        })
      );
  }

  //   deleteProductImageResizer(id) {
  //     const api = this._config?.apiConfig?.serviceUrlv1;
  //     const companyID = LocalStorageUtils.companyId;
  //     const URL = `${api}/${companyID}/image-resizer/${id}`;

  //     return this._httpClient.delete<EcommifyApiResponse<any>>(URL).pipe(
  //       catchError(error => {
  //         console.log('settings/ImageResizer: delete ImageResizer error', error);
  //         return of(error);
  //       })
  //     );
  //   }

  getGroup(
    page: number = 0,
    size: number = 10,
    sort: string = 'name',
    order: 'asc' | 'desc' | '' = 'asc',
    search: string = ''
  ): Observable<EcommifyApiResponse<any>> {
    const api = this._config?.apiConfig?.serviceUrlv1;
    const companyID = LocalStorageUtils.companyId;

    return this._httpClient
      .post<EcommifyApiResponse<any>>(`${api}/${companyID}/group/search`, {
        params: {
          page: '' + page,
          size: '' + size,
          sort,
          order,
          search,
        },
      })
      .pipe(
        tap((response: any) => {}),
        catchError((_error: any) => {
          return of(null);
        })
      );
  }

  delete(id: string) {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;
    return new Observable(observable => {
      this._httpClient
        .delete(`${api}/${companyID}/image-resizer/${id}`)
        .subscribe({
          next: (res: any) => {
            if (res.success) {
              this._snackbarService.showSuccess(res.message);
              // this.toastr.success({ detail: "Success", summary: res.message });
              observable.next(res);
            } else {
              observable.error(res);
              this._snackbarService.showError(res.message);
              // this.toastr.error({ detail: "Error", summary: res.message });
            }
          },
        }),
        (err: any) => {
          this._snackbarService.showError(err.error.message);
          observable.error(err);
        };
    });
  }
}

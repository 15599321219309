import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ProductGroupsService } from 'app/modules/product-groups/product-groups.service';
import { ProductGroup } from 'app/modules/product-groups/product-groups.types';
import { isNumber } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { SnackbarService } from 'app/services/snackbar.service';
import { ProductService } from '@services';

@Component({
  selector: 'eco-group-filter',
  templateUrl: './group-filter.component.html',
  styleUrls: ['./group-filter.component.scss'],
})
export class GroupFilterComponent implements OnInit, OnDestroy {
  @Output() onApplyFilter: EventEmitter<any> = new EventEmitter();
  @Output() onClearFilter: EventEmitter<any> = new EventEmitter();

  @Input('selectedFilter') set _selectedFilter(value: any) {
    console.log('value', value);
    if (value) {
      this.form['group_id'] = value?.group_id || [];
      this.form['operator'] = value?.operator || '';
    }
  }

  groupList: ProductGroup[] = [];
  backupGroupList: ProductGroup[] = [];
  searchGroupText: string;

  operatorOptions: any[] = [
    { value: 'in', label: 'In' },
    { value: '!in', label: 'Not In' },
  ];

  form: any = {
    group_id: [],
    operator: '',
  };
  selectedFilterBlockIndex: number;
  editBlockIndex: number;
  editFilterIndex: number;

  isExpanded: boolean = true;
  isEditPanelOpen: boolean = false;
  panelOpenFor: 'ADD' | 'EDIT' = 'ADD';
  _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private _productGroupsService: ProductGroupsService,
    private productService: ProductService,
    private _snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.getGroups();
  }

  onSearchGroup() {
    if (!this.searchGroupText?.length) {
      this.groupList = [...this.backupGroupList];
      return;
    }
    this.groupList = this.backupGroupList.filter(elem => {
      if (elem.name?.toLowerCase().includes(this.searchGroupText.toLowerCase()))
        return true;
      else false;
    });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  applyColumnFilter() {
    if (!this.form.operator) {
      this._snackbarService.showError('Fill out all the necessary fields');
      return;
    }
    this.onApplyFilter.emit(this.form);
  }

  resetForm() {
    this.form = {
      group_id: [],
      operator: '',
    };
  }

  clearFilterByButton() {
    this.resetForm();
    this.onClearFilter.emit({});
  }

  clearFilter() {
    this.resetForm();
  }

  private getGroups() {
    this.productService
      .getProductGroupsData(0, 1000)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(response => {
        if (response) {
          const { result } = response;
          this.groupList = result.groups.sort((a: any, b: any) =>
            a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
          );
          this.backupGroupList = [...this.groupList];
        }
      });
  }
}

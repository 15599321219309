import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';
import { Pagination } from 'app/layout/common/grid/grid.types';
import { appConfig } from 'app/core/config/app.config';
import { EcommifyApiResponse } from 'app/core/api/api.types';

import { LocalStorageUtils } from 'app/core/common/local-storage.utils';
import { GridUtils } from '../layout/common/grid/grid.utils';
import { SnackbarService } from 'app/services/snackbar.service';
// import { ProductPromotionResponse } from './product-image-resizer.types';

@Injectable({
  providedIn: 'root',
})
export class ProductInventoryLocationService {
  // Private
  private _config = appConfig;
  private _productPromotionData: BehaviorSubject<any | null> =
    new BehaviorSubject(null);

  private _productInventoryLocationsData: BehaviorSubject<any | null> =
    new BehaviorSubject(null);
  private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(
    null
  );

  constructor(
    private _snackbarService: SnackbarService,
    private _httpClient: HttpClient
  ) {}

  // Getter for Product Promotion Data Dashboard
  get ProductPromotionData$(): Observable<any> {
    return this._productPromotionData.asObservable();
  }

  // Getter for Product Image Resizer Data Dashboard
  get ProductInventoryLocationsData$(): Observable<any> {
    return this._productInventoryLocationsData.asObservable();
  }

  // Getter for pagination
  get pagination$(): Observable<Pagination> {
    return this._pagination.asObservable();
  }

  /**
   * Get InventoryLocations Dashboard Data
   * @param page , @param size, @param sort, @param order, @param search // TBD
   */
  getProductInventoryLocationsData(
    page: number = 0,
    size: number = 50,
    search: string = ''
  ): Observable<EcommifyApiResponse<any>> {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;

    return this._httpClient
      .post<EcommifyApiResponse<any>>(
        `${api}/${companyID}/inventory-locations/search`,
        {
          page: '' + page,
          size: '' + size,
          search,
        }
      )
      .pipe(
        tap((response: any) => {
          const { result } = response;

          const pagination = GridUtils.getPagination(result);
          console.log(pagination);
          this._pagination.next(pagination);
          this._productInventoryLocationsData.next(result?.image_resizer);
        }),
        catchError((_error: any) => {
          return of(null);
        })
      );
  }

  saveProductInventoryLocationsData(body, id: any = null) {
    const api = this._config?.apiConfig?.serviceUrlv1;
    const companyID = LocalStorageUtils.companyId;
    const URL = id
      ? `${api}/${companyID}/inventory-locations/${id}`
      : `${api}/${companyID}/inventory-locations`;
    const Method = id ? 'put' : 'post';

    return this._httpClient[Method]<EcommifyApiResponse<any>>(URL, body).pipe(
      catchError(error => {
        this._snackbarService.showError(error?.error?.message);
        console.log(
          'settings/InventoryLocations: create/Update InventoryLocations error',
          error
        );
        return of(error);
      })
    );
  }

  delete(id: string) {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;
    return new Observable(observable => {
      this._httpClient
        .delete(`${api}/${companyID}/inventory-locations/${id}`)
        .subscribe({
          next: (res: any) => {
            if (res.status) {
              this._snackbarService.showSuccess(res.message);
              // this.toastr.success({ detail: "Success", summary: res.message });
              observable.next(res);
            } else {
              observable.error(res);
              this._snackbarService.showError(res.message);
              // this.toastr.error({ detail: "Error", summary: res.message });
            }
          },
        }),
        (err: any) => {
          this._snackbarService.showError(err.error.message);
          observable.error(err);
        };
    });
  }
}

<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative">
    <img class="w-10 h-10 rounded-full" *ngIf="showAvatar && user.avatar" [src]="user?.avatar" />
    <div class="flex items-center justify-center w-8 h-8 rounded-full text-primary-900 bg-primary-100"
      *ngIf="!showAvatar || !user.avatar">
      <span>{{ user?.name | getInitials }}</span>
    </div>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>Signed in as</span>
      <span class="mt-1.5 text-md font-medium">{{ user?.email }}</span>
    </span>
  </button>

  <!-- <button mat-menu-item>
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span>Profile</span>
  </button> -->

  <!--<div *ngIf="showSettings()">
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="gotoSettings()">
      <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
      <span>Settings</span>
    </button>
  </div>-->
  <div *ngIf="showBackButton()">
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="goBacktoAdmin()">
      <mat-icon [svgIcon]="'heroicons_outline:arrow-circle-left'"></mat-icon>
      <span>Back to admin</span>
    </button>
  </div>

  <!-- <button mat-menu-item [matMenuTriggerFor]="userStatus">
    <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
    <span>Status</span>
  </button> -->
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>

<!-- <mat-menu class="user-status-menu" #userStatus="matMenu">
  <button mat-menu-item (click)="updateUserStatus('online')">
    <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
    <span>Online</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('away')">
    <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
    <span>Away</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('busy')">
    <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
    <span>Busy</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('not-visible')">
    <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
    <span>Invisible</span>
  </button>
</mat-menu> -->
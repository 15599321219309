import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  tap,
  throwError,
} from 'rxjs';
import { appConfig } from 'app/core/config/app.config';
import { EcommifyApiResponse } from 'app/core/api/api.types';

import { LocalStorageUtils } from 'app/core/common/local-storage.utils';
import { GridUtils } from '../layout/common/grid/grid.utils';
import { SnackbarService } from 'app/services/snackbar.service';
import { ApiQueryParamsService } from './misc/api-query-params.service';
import { Pagination } from 'app/layout/common/grid/grid.types';
// import { ProductPromotionResponse } from './product-image-resizer.types';

@Injectable({
  providedIn: 'root',
})
export class CompanyUserService {
  // Private
  private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(
    null
  );
  private _config = appConfig;
  get pagination$(): Observable<Pagination> {
    return this._pagination.asObservable();
  }

  constructor(
    private _httpClient: HttpClient,
    private _snackbarService: SnackbarService,
    private _apiQueryParamsService: ApiQueryParamsService
  ) {}

  getUsers(paramsObj): Observable<EcommifyApiResponse<any>> {
    const api = this._config?.apiConfig?.baseUrl;
    const companyID = LocalStorageUtils.companyId;
    return this._httpClient
      .get<EcommifyApiResponse<any>>(
        `${api}/${companyID}/users${this._apiQueryParamsService.get(paramsObj)}`
      )
      .pipe(
        catchError(error => {
          console.error('Error occurred during post request:', error);
          // Re-throw the error to propagate it downstream
          return throwError(() => error);
        }),
        map((response: any) => {
          if (!response.success) {
            throw new Error(response.result);
          }
          const pagination = GridUtils.getPagination(response.result);
          this._pagination.next(pagination);
          return response?.result?.users || [];
        })
      );
  }

  post(val): Observable<EcommifyApiResponse<any>> {
    const api = this._config?.apiConfig?.baseUrl;
    const companyID = LocalStorageUtils.companyId;
    return this._httpClient
      .post<EcommifyApiResponse<any>>(`${api}/${companyID}/user`, val)
      .pipe(
        catchError(error => {
          this._snackbarService.showError(error?.errors?.message);
          return of(error);
        }),
        map((response: any) => {
          if (!response.success) {
            throw new Error(response);
          }
          return response.result;
        })
      );
  }

  put(val): Observable<EcommifyApiResponse<any>> {
    const api = this._config?.apiConfig?.baseUrl;
    const companyID = LocalStorageUtils.companyId;
    return this._httpClient
      .put<EcommifyApiResponse<any>>(`${api}/${companyID}/user`, val)
      .pipe(
        catchError(error => {
          this._snackbarService.showError(error?.error?.errors?.message);
          return of(error);
        }),
        map((response: any) => {
          if (!response.success) {
            throw new Error(response);
          }
          return response.result;
        })
      );
  }

  updateRole() {}

  adminPut(val): Observable<EcommifyApiResponse<any>> {
    const api = this._config?.apiConfig?.baseUrl;
    const companyID = LocalStorageUtils.companyId;
    return this._httpClient
      .put<EcommifyApiResponse<any>>(`${api}/admin/user/${val.id}`, val)
      .pipe(
        catchError(error => {
          this._snackbarService.showError(error?.error?.errors?.message);
          return of(error);
        }),
        map((response: any) => {
          if (!response.success) {
            throw new Error(response);
          }
          return response.result;
        })
      );
  }

  //   put(id) {
  //     const api = this._config?.apiConfig?.serviceUrlv1;
  //     const companyID = LocalStorageUtils.companyId;

  //     return this._httpClient
  //       .put<EcommifyApiResponse<any>>(`${api}/${companyID}/user/${id}`, {})
  //       .pipe(
  //         catchError(error => {
  //           console.log(
  //             'settings/ImageResizer: create/Update ImageResizer error',
  //             error
  //           );
  //           return of(error);
  //         })
  //       );
  //   }

  delete(id: string) {
    const api = this._config?.apiConfig?.serviceUrl;
    const companyID = LocalStorageUtils.companyId;
    return new Observable(observable => {
      this._httpClient.delete(`${api}/${companyID}/user/${id}`).subscribe({
        next: (res: any) => {
          if (res.success) {
            this._snackbarService.showSuccess(res.message);
            // this.toastr.success({ detail: "Success", summary: res.message });
            observable.next(res);
          } else {
            observable.error(res);
            this._snackbarService.showError(res.message);
            // this.toastr.error({ detail: "Error", summary: res.message });
          }
        },
      }),
        (err: any) => {
          this._snackbarService.showError(err.error.message);
          observable.error(err);
        };
    });
  }
}

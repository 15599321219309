import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductService } from '@services';

@Component({
  selector: 'app-product-category-tree-node',
  templateUrl: './product-category-tree-node.component.html',
  styleUrls: ['./product-category-tree-node.component.scss'],
})
export class ProductCategoryTreeNodeComponent {
  selectedParentNode: any = {};
  @Input() categories: any[] = [];
  @Input() isChildren: boolean = false;
  @Input() selectedCategories: any[] = [];
  @Output() OnCategorySelect: EventEmitter<any> = new EventEmitter();
  @Input() set childCategory(val: any) {
    console.log(val);
  }
  constructor(private productService: ProductService) {}

  onCategorySelect(val) {
    this.OnCategorySelect.emit(val);
  }

  onLoadChildren(val) {
    console.log(val);
    this.selectedParentNode = val;
    val['showChildren'] = !val['showChildren'];
    if (val.has_children && !val['children']?.length) {
      const object = {
        search: '',
        parent: this.selectedParentNode.code,
        size: 1000,
      };
      this.productService.getCategories(object).subscribe((response: any) => {
        val['children'] = response.category.map(elem => {
          const Index = this.selectedCategories?.findIndex(
            categoryElem => categoryElem.code === elem.code
          );
          if (Index > -1) elem.isSelected = true;
          else elem.isSelected = false;
          return elem;
        });
      });
    }
  }
}

<div class="product-category-filter">
  <div class="panel-top">
    <div class="header-wrapper">
      <div class="modal-header">
        <h2 class="text-[20px]">Add filter</h2>
      </div>
      <div class="modal-actions ng-star-inserted">
        <button
          class="eco-btn eco-btn-sm eco-btn-primary mr-8px"
          [disabled]="!selectedCategories?.length"
          (click)="onSave()">
          Save
        </button>
        <button
          class="eco-btn eco-btn-sm eco-btn-outline-light"
          (click)="onClose()">
          Cancel
        </button>
      </div>
    </div>
  </div>
  <fuse-loading-bar></fuse-loading-bar>
  <div class="panel-main" *ngIf="!isLoading">
    <div class="modal-body">
      <div class="categories">
        <div class="category-select-tree">
          <div class="categories-column-header">
            <h3 class="text-[16px]">Available categories</h3>
            <span
              class="fs-12 eco-clickable center text-underline"
              (click)="onCollapseAll()"
              >Collapse all groups</span
            >
          </div>
          <div class="mt-16px">
            <mat-form-field
              class="w-100 search-attribute-input mr-4 flex-1 h-10">
              <mat-icon matPrefix>search</mat-icon>
              <input
                matInput
                class="p-0"
                (keyup)="searchCategory(inputSearch.value)"
                placeholder="Search by category name"
                #inputSearch />
              <mat-icon
                matPostfix
                class="cursor-pointer"
                (click)="
                  inputSearch.value = ''; searchCategory(inputSearch.value)
                "
                >close</mat-icon
              >
            </mat-form-field>
          </div>
          <div
            class="overflow-auto pl-16px"
            style="height: calc(100vh - 210px)">
            <app-product-category-tree-node
              [categories]="categories"
              [selectedCategories]="selectedCategories"
              (OnLoadChildren)="onLoadChildren($event)"
              (OnCategorySelect)="
                onCategorySelect($event)
              "></app-product-category-tree-node>
          </div>

          <!-- <div class="mt-16px category-tree">
            <div
              class="category-tree-list"
              *ngFor="let category of categories; trackBy: trackByFn">
              <div class="category-node">
                <div><mat-checkbox class="example-margin"></mat-checkbox></div>
                <div class="center">
                  <i class="pi pi-angle-right"></i>
                </div>
                <div class="ml-8px v-center">{{ category.label }}</div>
              </div>
            </div>
          </div> -->
        </div>

        <div class="tree-container flex-1">
          <div class="selected-categories">
            <div class="categories-column-header">
              <h3 class="text-[16px]">Selected Categories</h3>
              <span
                class="fs-12 eco-clickable center text-underline"
                (click)="clearAllSelected()"
                >Clear all</span
              >
            </div>
          </div>
          <div class="items">
            <div class="item" *ngFor="let category of selectedCategories">
              <div class="fs-12">{{ category.label }}</div>
              <div class="flex gap-3">
                <div class="item-path m-auto w-200px truncate text-right">
                  <span
                    *ngFor="
                      let path of category.path?.length ? category.path : [];
                      let first = first;
                      let last = last
                    "
                    ><span *ngIf="first">/</span> {{ path }}
                    <span *ngIf="!last">></span></span
                  >
                </div>
                <div class="remove-button">
                  <i
                    class="pi pi-times"
                    (click)="removeSelectedCategory(category)"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-32px center" *ngIf="!selectedCategories?.length">
            <div>
              <div>
                <img
                  src="assets/eco-images/categories-not-found.svg"
                  style="height: 79px; margin-top: 32px" />
              </div>
              <div class="text-center text-secondary">
                Choose categories from the left panel
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
